import { IEmailService, IFirebaseService } from "../interfaces/services.interfaces";
import { IDynamicEmailTemplateParams, ISignUpBetaFormValues } from "../interfaces/form.interfaces";
import AbstractService from './abstract.service';
import { getTypedObjectKeys } from "../utils/typescript.utils";
import { env } from './../env';

export default class EmailService extends AbstractService implements IEmailService {

    constructor({ firebaseService }: { firebaseService: IFirebaseService }) {
        super({ firebaseService });
    }

    // send a server down email - via a thirdparty server as our own one is down,
    // which is the reason for sending the email ;)
    public async sendServerDownEmail(): Promise<void> {

        const url = 'https://api.emailjs.com/api/v1.0/email/send';
        const data = {
            service_id: env.REACT_APP_EMAILJS_SERVICE_ID,
            template_id: env.REACT_APP_EMAILJS_TEMPLATE_ID,
            user_id: env.REACT_APP_EMAILJS_USER_ID,
            template_params: {
                'server_name': `${env.REACT_APP_SERVER_URL}, in particular ${window.location.href}`,
            }
        };
        await this.axiosInstance.post(url, data);
    }

    public async sendBetaEmail(params: ISignUpBetaFormValues): Promise<void> {
        const url = `${env.REACT_APP_SERVER_URL}/email/beta`;
        const transformedParams = this.buildBetaDynamicEmailTemplateParams(params);
        await this.axiosInstance.post(url, transformedParams);
    }

    public async sendFeedbackEmail(feedback: string): Promise<void> {
        const url = `${env.REACT_APP_SERVER_URL}/email/feedback`;
        await this.axiosInstance.post(url, { feedback });
    }

    private buildBetaDynamicEmailTemplateParams(params: ISignUpBetaFormValues): IDynamicEmailTemplateParams {
        const keys = getTypedObjectKeys(params);
        return {
            userInputKeyValuePairs: keys.map(key => ({
                key,
                value: params[key]
            }))
        }
    }
}
