import {
  IEmailService,
  IUserService,
  IEventService,
  IPipelineService,
  IFirebaseService,
  IServices,
  IReportService,
  IProjectService,
  IImageService,
  ISharepointService,
  IVideoService,
  ISegmentService,
  IGridService,
  IDroneService,
} from "../interfaces/services.interfaces";

import Firebase from "./firebase.service";
import EmailService from "./email.service";
import UserService from "./user.service";
import EventService from "./event.service";
import PipelineService from "./pipeline.service";
import ReportService from "./report.service";
import ProjectService from "./project.service";
import ImageService from "./image.service";
import SharepointService from "./sharepoint.service";
import VideoService from "./video.service";
import SegmentService from "./segment.service";
import GridService from "./grid.service";
import DroneService from "./drone.service";
import SviEventService from "./svi.service";
export default class Services implements IServices {
  public firebaseService: IFirebaseService;
  public emailService: IEmailService;
  public userService: IUserService;
  public eventService: IEventService;
  public sviEventService: IEventService;
  public pipelineService: IPipelineService;
  public reportService: IReportService;
  public projectService: IProjectService;
  public imageService: IImageService;
  public sharepointService: ISharepointService;
  public videoService: IVideoService;
  public segmentService: ISegmentService;
  public gridService: IGridService;
  public droneService: IDroneService;

  constructor() {
    this.firebaseService = new Firebase();
    this.emailService = new EmailService({
      firebaseService: this.firebaseService,
    });
    this.userService = new UserService({
      firebaseService: this.firebaseService,
    });

    this.eventService = new EventService({
      firebaseService: this.firebaseService,
    });

    this.sviEventService = new SviEventService({
      firebaseService: this.firebaseService,
    });

    this.pipelineService = new PipelineService({
      firebaseService: this.firebaseService,
    });

    this.reportService = new ReportService({
      firebaseService: this.firebaseService,
    });
    this.projectService = new ProjectService({
      firebaseService: this.firebaseService,
    });

    this.imageService = new ImageService({
      firebaseService: this.firebaseService,
    });
    this.sharepointService = new SharepointService({
      firebaseService: this.firebaseService,
    });
    this.videoService = new VideoService({
      firebaseService: this.firebaseService,
    });
    this.segmentService = new SegmentService({
      firebaseService: this.firebaseService,
    });
    this.gridService = new GridService({
      firebaseService: this.firebaseService,
    });
    this.droneService = new DroneService({
      firebaseService: this.firebaseService,
    });
  }
}
