import * as ROUTES from "../../constants/routes";
import {Link} from "react-router-dom";
import React, {useContext, useState} from "react";
import {IFirebaseAuthUser} from "../../interfaces/authentication.interfaces";
import withAuthorization from "../HOC/with.authorization";
import {ResultPageAbstract} from "../Result/resultPageAbstract";
import {AccountSettingsLink} from "../Account";
import AppContext from "../../context/app.context";
import {ChangeFieldForm} from "../ChangeFieldForm/change.field.form";
import AuthenticationValidatorLogic from "../../logic/authenticationValidatorLogic";
import {IAppContext} from "../../interfaces/context.interfaces";
import Style from '../../styles/Pages.module.css'
import LabelStyle from "../../styles/Form.module.css";
import {capitalize} from "../../utils/string.utils";


const ReauthenticatedSuccessfully = () => {
    return (
        <ResultPageAbstract
            status="success"
            title={ ((window as any).language === "de" ) ? "Erfolgreich neu authentifiziert" : "Successfully Reauthenticated using credentials!" }
            extra={[
                AccountSettingsLink()
            ]}
        />
    );
};

const FIELD_NAME = "password";

const ReauthenticatePage = () => {

    // state counter to enable refreshing this component
    const [refreshCounter, setRefreshCounter] = useState(0);
    const refresh = () => setRefreshCounter(refreshCounter + 1);

    const appContext: IAppContext = useContext(AppContext);

    if (!appContext?.services?.firebaseService) {
        return null;
    }

    // as long as the user data has not yet been retrieved...
    if ((appContext as any)?.userData == null) {
      // ... refresh this component once a second
      window.setTimeout(refresh, 1000);
      return null;
    }

    let language = (appContext as any)?.userData?.language;
    (window as any).language = language;

    return (
        <div className={Style.page}>
            <div className={Style.formContainer}>
                <h3>
                    In order to protect against sensitive operations you are asked to type your old password.
                    After that you can go back to the account page and try again.
                </h3>
                <ChangeFieldForm
                    formHeading={ (language === "de" ) ? "Neu authentifizieren" : "Reauthenticate" }
                    SuccessResult={ReauthenticatedSuccessfully}
                    fieldName={FIELD_NAME}
                    fieldValidators={[]}
                    fieldNodes={
                        (key: string) => [
                            <div className={LabelStyle.inputAndLabelPlaceholder}>
                                <input type="password" name={key} id={key} placeholder={capitalize(FIELD_NAME)}/>
                                <label htmlFor={key}>{capitalize(FIELD_NAME)}</label>
                            </div>,
                            <div className={LabelStyle.inputAndLabelPlaceholder}>
                                <input type="password" name={key} id={key} placeholder={ (language === "de" ) ? capitalize(FIELD_NAME) + " wiederholen" : "Repeat " + capitalize(FIELD_NAME) }/>
                                <label htmlFor={key}>{ (language === "de" ) ? "Wiederholen" : "Repeat" }</label>
                            </div>

                        ]
                    }
                    fieldMethod={appContext.services.firebaseService.reauthenticateUsingCredential.bind(appContext.services.firebaseService)}
                />
            </div>
        </div>
    );
};

export const ReAuthenticationLink = () => (
    <Link to={ROUTES.REAUTHENTICATE_PROMPT.route}>{ROUTES.REAUTHENTICATE_PROMPT.Label}</Link>
);

const condition = (authUser: IFirebaseAuthUser) => AuthenticationValidatorLogic.isAuthenticated(authUser);

export default withAuthorization(condition)(ROUTES.SIGN_IN.route)(ReauthenticatePage);
