declare global {
  interface Window {
    env: any;
  }
}

// change with your own variables
type EnvType = {
  REACT_APP_API_KEY: string;
  REACT_APP_AUTH_DOMAIN: string;
  REACT_APP_DATABASE_URL: string;
  REACT_APP_PROJECT_ID: string;
  REACT_APP_STORAGE_BUCKET: string;
  REACT_APP_MESSAGING_SENDER_ID: string;
  REACT_APP_ID: string;
  REACT_APP_MAPBOX_ACCESS_TOKEN: string;
  REACT_APP_SERVER_URL: string;
  REACT_APP_EMAILJS_SERVICE_ID: string;
  REACT_APP_EMAILJS_TEMPLATE_ID: string;
  REACT_APP_EMAILJS_USER_ID: string;
  REACT_APP_AWS_ACCESS_KEY: string;
  REACT_APP_AWS_SECRET_KEY: string;
  REACT_APP_TILES_URL: string;
  REACT_APP_SVX_REDIRECT_KEY: string;
  PUBLIC_URL: string;
  NODE_ENV: string;
  REACT_APP_GIS_PREFIX: string;
};
export const env: EnvType = { ...process.env, ...window.env };
