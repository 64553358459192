import React, {useContext} from 'react';
import withAuthorization from "../HOC/with.authorization";
import * as ROUTES from "../../constants/routes";
import {IFirebaseAuthUser} from "../../interfaces/authentication.interfaces";
import {PublicPageWithForm} from "../PublicPageWithForm/publicPageWithForm";
import AuthenticationValidatorLogic from "../../logic/authenticationValidatorLogic";
import EventImage from '../../assets/landing_page.jpg';
import RegistrationForm from '../SignUp/signup.form';
import AppContext from "../../context/app.context";

const HomePage = () => {
    const appContext = useContext(AppContext);
    return (
        <PublicPageWithForm
            imageSource={EventImage}
        >
            <RegistrationForm
                    userService={appContext.services.userService}
            />
        </PublicPageWithForm>
    );
};

const condition = (authUser: IFirebaseAuthUser) => !AuthenticationValidatorLogic.isAuthenticated(authUser);
export default withAuthorization(condition)(ROUTES.MAP.route)(HomePage);
