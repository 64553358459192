export const PHONE_REGEX: RegExp = /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[-.\\/]?)?((?:\(?\d+\)?[-.\\/]?)*)(?:[-.\\/]?(?:#|ext\.?|extension|x)[-.\\/]?(\d+))?$/i;
export const NO_NUMBERS_REGEX: RegExp = /[^0-9]/;
export const ONLY_NUMBERS_REGEX: RegExp = /^[0-9]*$/;

export const formItemLayout = {
    wrapperCol: {
        xs: { span: 16 },
        sm: { span: 24 },
    },
    layout: "vertical" as "vertical"
};
export const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 16,
            offset: 0,
        },
        sm: {
            span: 24,
            offset: 0,
        },
    },
};
