import React from "react";
import { Icon, Layout, Menu } from "antd";
import { Link, Route, Switch } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import { IProps, IState, withAuthentication } from "../HOC/with.authentication";
import PasswordForgetPage from "../ForgotPassword";
import AccountPage from "../Account";
import HomePage from "../Home";
import ChangePasswordPage from "../ChangePassword";
import ReauthenticatePage from "../ReAuthenticationPrompt";
import ChangeEmailPage from "../ChangeEmail";
import DeleteAccountPage from "../DeleteAccount";
import Styles from "./App.module.css";
import "mapbox-gl/dist/mapbox-gl.css";
import MapData from "../MapData/MapData";
import SignInPage from "../Login";
import BetaSignUpPage from "../Beta/BetaSignUp";
import {
  IFirebaseService,
  IUserService,
  IEmailService,
  IProjectService,
} from "../../interfaces/services.interfaces";
import AppContext from "../../context/app.context";
import { IAppContext } from "../../interfaces/context.interfaces";
import logo from "../../assets/logo.png";
import HelpPage from "../HelpPage";
import FeedbackPage from "../FeedbackPage";
import PipelinesPage from "../PipelinesPage";
import EventsPage from "../EventsPage";
import ReportsPage from "../ReportsPage";
import ChangelogPage from "../ChangelogPage";
import SharepointPage from "../SharepointPage";
import { env } from "./../../env";
import SegmentsPage from "../SegmentsPage";

const { Content, Sider } = Layout;

interface IAppProps extends IProps {}

interface IAppState extends IState {
  userData: any;
  projects: any;
  feedbackModalVisible: boolean;
  collapsed: boolean;
  refreshCounter: number;
}

class App extends React.Component<IAppProps, IAppState> {
  static contextType = AppContext;

  private userService: IUserService;
  private projectService: IProjectService;
  private emailService: IEmailService;
  private firebaseService: IFirebaseService;

  constructor(props: any, context: IAppContext) {
    super(props);

    this.state = {
      authUser: null,
      userData: null,
      projects: null,
      feedbackModalVisible: false,
      collapsed: false,
      refreshCounter: 0,
    };

    this.userService = context.services.userService;
    this.projectService = context.services.projectService;
    this.emailService = context.services.emailService;
    this.firebaseService = context.services.firebaseService;
    const boundOnAuthStateChanged = this.onAuthStateChanged.bind(this);
    this.firebaseService.auth.onAuthStateChanged(boundOnAuthStateChanged);

    // store app index so it can be updated from the outside when the location changes
    // outside the native react workflow (as it is based on the window location)
    (window as any)._appIndex = this;
  }

  updateNav = function () {
    window.setTimeout(function () {
      if ((window as any)._appIndex) {
        (window as any)._appIndex.forceUpdate();
      }
    }, 10);
    window.setTimeout(function () {
      if ((window as any)._appIndex) {
        (window as any)._appIndex.forceUpdate();
      }
    }, 1000);
  };

  onCollapse = (collapsed: boolean) => {
    this.setState({ collapsed });
  };

  onAuthStateChanged(user: any) {
    if (user) {
      this.userService
        .getMyData()
        .then((data: any) => {
          const userData = data?.data;

          this.setState({
            userData: userData,
          });

          // if we actually get some state from the backend...
          if (userData != null) {
            // ... and if we are logging in for the first time...
            console.log("got data:");
            console.log(userData);
            const company =
              typeof userData?.company !== "undefined"
                ? userData.company
                : "Demo";
            localStorage.setItem("company", company);
            (window as any)._userData = userData;
            if (userData.firstLoginFlag) {
              this.userService.addData({
                firstLoginFlag: false,
              });
            }
            this.context.userData = userData;
          } else {
            this.context.userData = {};
          }
        })
        .catch((error: any) => {
          console.log("server error upon getting user data!");
          console.log(error);
          (window as any).encounteredError = true;
        });

      this.projectService
        .getMyData()
        .then((data: any) => {
          const projects = data?.data[0];

          this.setState({
            projects: projects,
          });

          // if we actually get some state from the backend...
          if (projects != null) {
            // ... and if we are logging in for the first time...
            console.log("got project data:");
            console.log(projects);
            const company =
              typeof projects?.companyName !== "undefined"
                ? projects.companyName
                : "Demo";
            localStorage.setItem("company", company);
            (window as any)._projects = projects;
            this.context.projects = projects;
          } else {
            this.context.userData = {};
          }
        })
        .catch((error: any) => {
          console.log("server error upon getting user data!");
          console.log(error);
          (window as any).encounteredError = true;
        });
    }
  }

  logout = async () => {
    await this.firebaseService.signOut();
    window.location.href = ROUTES.SIGN_IN.route;
  };

  render() {
    // state counter to enable refreshing this component
    const refresh = () =>
      this.setState({ refreshCounter: this.state.refreshCounter + 1 });

    // as long as the user data has not yet been retrieved...
    if ((this.context as any)?.userData == null) {
      // ... refresh this component once a second
      window.setTimeout(refresh, 1000);
    }

    let embeddedApp = (this.context as any)?.projects?.app === "svi" ? true : false
    let language = (this.context as any)?.userData?.language;

    const { collapsed } = this.state;

    // by default, select the map
    let selectedKeys = ["1"];

    switch (window.location.pathname) {
      case env.REACT_APP_GIS_PREFIX + ROUTES.PIPELINES.route:
        selectedKeys = ["2"];
        break;
      case env.REACT_APP_GIS_PREFIX + ROUTES.SEGMENTS.route:
        selectedKeys = ["2"];
        break;
      case env.REACT_APP_GIS_PREFIX + ROUTES.EVENTS.route:
        selectedKeys = ["3"];
        break;
      case env.REACT_APP_GIS_PREFIX + ROUTES.EVENTS_ARCHIVE.route:
        selectedKeys = ["4"];
        break;
      case env.REACT_APP_GIS_PREFIX + ROUTES.ACCOUNT.route:
        selectedKeys = ["5"];
        break;
      case env.REACT_APP_GIS_PREFIX + ROUTES.FEEDBACK.route:
        selectedKeys = ["6"];
        break;
      case env.REACT_APP_GIS_PREFIX + ROUTES.HELP.route:
        selectedKeys = ["7"];
        break;
      case env.REACT_APP_GIS_PREFIX + ROUTES.REPORTS.route:
        selectedKeys = ["8"];
        break;
      // case env.REACT_APP_GIS_PREFIX + ROUTES.CHANGELOG.route:
      //   selectedKeys = ["9"];
      //   break;
      case env.REACT_APP_GIS_PREFIX + ROUTES.SHAREPOINT.route:
        selectedKeys = ["11"];
        break;
    }

    if ((window as any).encounteredError === true) {
      if ((window as any).sentEmailAboutError !== true) {
        (window as any).sentEmailAboutError = true;
        this.emailService.sendServerDownEmail();
      }
    }
    if (
      window.location.pathname ==
        env.REACT_APP_GIS_PREFIX + ROUTES.SIGN_IN.route ||
      window.location.pathname == env.REACT_APP_GIS_PREFIX + ROUTES.HOME.route
    ) {
      return (
        <Layout className={Styles.layout}>
          <Content className={`${Styles.content}`}>
            <Switch>
              <Route exact path={ROUTES.HOME.route} component={HomePage} />
              <Route exact path={ROUTES.SIGN_IN.route} component={SignInPage} />
            </Switch>
          </Content>
        </Layout>
      );
    } else {
      return (
        <Layout className={Styles.layout}>
          <div
            style={{
              display:
                (window as any).encounteredError === true ? "block" : "none",
              position: "fixed",
              right: "25%",
              zIndex: 99,
              top: "35%",
              width: "315pt",
              textAlign: "center",
              padding: "9pt 1pt 9pt 1pt",
              background: "#FFF",
              borderRadius: "15pt",
              color: "#111",
            }}
          >
            <div>
              {language === "de"
                ? "Die Kommunikation mit dem Server ist unterbrochen."
                : "Communication with the server stopped;"}
            </div>
            <div>
              {language === "de"
                ? "Der Server ist zur Zeit wegen Wartungsarbeiten nicht erreichbar. Wir bitten um Entschuldigung."
                : "Server is down for maintenance. We apologize for the inconvenience."}
            </div>
            <div>
              {language === "de"
                ? "Bitte laden Sie diese Seite in einer Weile erneut."
                : "Please refresh this page later."}
            </div>
          </div>

          <Sider
            className={Styles.sider}
            collapsible
            collapsed={collapsed}
            onCollapse={this.onCollapse}
          >
            <div
              style={{
                width: "120px",
                height: "30px",
                margin: "18px 24px 16px 26px",
              }}
            >
              <img
                style={{ width: "100%", height: "100%" }}
                src={logo}
                alt={"SuperVision"}
              />
            </div>           
            <Menu
              theme="dark"
              selectedKeys={selectedKeys}
              mode="inline"
              style={{ maxHeight: "82vh", overflow: "auto" }}
            >
              <Menu.Item key="1">
                <Icon type="environment" />
                <span>{language === "de" ? "Karte" : "Map"}</span>
                <Link to={ROUTES.MAP.route} onClick={this.updateNav} />
              </Menu.Item> 
              {embeddedApp ? 
                <Menu.Item key="2">
                  <Icon type="branches" />
                  <span>AOIs</span>
                  <Link to={ROUTES.SEGMENTS.route} onClick={this.updateNav} />
                </Menu.Item> : null }
              {!embeddedApp ? 
                <Menu.Item key="2">
                  <Icon type="branches" />
                  <span>{(language === "de" ? "Pipelines" : "Pipelines")}</span>
                  <Link to={ROUTES.PIPELINES.route} onClick={this.updateNav} />
                </Menu.Item> : null}
              <Menu.Item key="3">
                <Icon type="exclamation" />
                <span>{language === "de" ? "Ereignisse" : "Events"}</span>
                <Link to={ROUTES.EVENTS.route} onClick={this.updateNav} />
              </Menu.Item>
              <Menu.Item key="4">
                <Icon type="database" />
                <span>{language === "de" ? "Archiv" : "Archive"}</span>
                <Link
                  to={ROUTES.EVENTS_ARCHIVE.route}
                  onClick={this.updateNav}
                />
              </Menu.Item>
              <Menu.Item key="11">
                <Icon type="upload" />
                <span>{language === "de" ? "Sharepoint" : "Sharepoint"}</span>
                <Link to={ROUTES.SHAREPOINT.route} onClick={this.updateNav} />
              </Menu.Item>
              {this.state.userData?.edit_settings && (
                <Menu.Item key="5">
                  <Icon type="user" />
                  <span>
                    {language === "de" ? "Einstellungen" : "Account settings"}
                  </span>
                  <Link to={ROUTES.ACCOUNT.route} onClick={this.updateNav} />
                </Menu.Item>
              )}
              <Menu.Item key="6" disabled={false}>
                <Icon type="heart" />
                <span>{language === "de" ? "Feedback" : "Feedback"}</span>
                <Link to={ROUTES.FEEDBACK.route} onClick={this.updateNav} />
              </Menu.Item>
              {/* <Menu.Item key="7" disabled={false}>
                <Icon type="question" />
                <span>{language === "de" ? "Hilfe" : "Help"}</span>
                <Link to={ROUTES.HELP.route} onClick={this.updateNav} />
              </Menu.Item> */}
              <Menu.Item key="8">
                <Icon type="bar-chart" />
                <span>{language === "de" ? "Berichte" : "Reports"}</span>
                <Link to={ROUTES.REPORTS.route} onClick={this.updateNav} />
              {/* </Menu.Item>
              <Menu.Item key="9">
                <Icon type="file-done" />
                <span>
                  {language === "de" ? "Änderungsprotokoll" : "Changelog"}
                </span>
                <Link to={ROUTES.CHANGELOG.route} onClick={this.updateNav} /> */}
              </Menu.Item>
              <Menu.Item key="10">
                <Icon type="file" />
                <span>{language === "de" ? "Impressum" : "Imprint"}</span>
                <a
                  href={ROUTES.IMPRINT.route}
                  target="_blank"
                  onClick={this.updateNav}
                />
              </Menu.Item>
              <Menu.Item onClick={this.logout}>
                <Icon type="logout" />
                <span>{language === "de" ? "Ausloggen" : "Logout"}</span>
              </Menu.Item>
            </Menu>
          </Sider>

          <Layout className={Styles.layout}>
            <Content className={`${Styles.content}`}>
              <Switch>
                <Route exact path={ROUTES.HOME.route} component={HomePage} />
                <Route exact path={ROUTES.MAP.route} component={MapData} />
                <Route
                  exact
                  path={ROUTES.SIGN_IN.route}
                  component={SignInPage}
                />
                {this.state.userData?.edit_settings && (
                  <Route
                    exact
                    path={ROUTES.ACCOUNT.route}
                    component={AccountPage}
                  />
                )}
                <Route
                  exact
                  path={ROUTES.PASSWORD_FORGET.route}
                  component={PasswordForgetPage}
                />
                <Route
                  exact
                  path={ROUTES.CHANGE_EMAIL.route}
                  component={ChangeEmailPage}
                />
                <Route
                  exact
                  path={ROUTES.CHANGE_PASSWORD.route}
                  component={ChangePasswordPage}
                />
                <Route
                  exact
                  path={ROUTES.DELETE_ACCOUNT.route}
                  component={DeleteAccountPage}
                />
                <Route
                  exact
                  path={ROUTES.REAUTHENTICATE_PROMPT.route}
                  component={ReauthenticatePage}
                />
                <Route
                  exact
                  path={ROUTES.BETA_SIGN_UP.route}
                  component={BetaSignUpPage}
                />
                {/* <Route exact path={ROUTES.HELP.route} component={HelpPage} /> */}
                <Route
                  exact
                  path={ROUTES.FEEDBACK.route}
                  component={FeedbackPage}
                />
                <Route
                  exact
                  path={ROUTES.PIPELINES.route}
                  component={PipelinesPage}
                />
                <Route
                  exact
                  path={ROUTES.SEGMENTS.route}
                  component={SegmentsPage}
                />
                <Route
                  exact
                  path={ROUTES.EVENTS.route}
                  component={EventsPage}
                />
                <Route
                  exact
                  path={ROUTES.EVENTS_ARCHIVE.route}
                  component={EventsPage}
                />
                <Route
                  exact
                  path={ROUTES.REPORTS.route}
                  component={ReportsPage}
                />
                {/* <Route
                  exact
                  path={ROUTES.CHANGELOG.route}
                  component={ChangelogPage}
                /> */}
                {/* <Route exact path={ROUTES.IMPRINT.route} /> */}
                <Route
                  exact
                  path={ROUTES.SHAREPOINT.route}
                  component={SharepointPage}
                />
              </Switch>
            </Content>
          </Layout>
        </Layout>
      );
    }
  }
}

export default withAuthentication(App);
