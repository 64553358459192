import {
  IFirebaseService,
  IVideoService,
} from '../interfaces/services.interfaces'
import AbstractService from './abstract.service'
import { env } from '../env'

const URL = env.REACT_APP_SERVER_URL + '/video'

export default class VideoService
  extends AbstractService
  implements IVideoService
{
  constructor({ firebaseService }: { firebaseService: IFirebaseService }) {
    super({ firebaseService })
  }

  public async getSignedUrl(videoId: string): Promise<string | null> {
    try {
      const { preSignedUrl } = (await (
        await this.axiosInstance.get(URL + '/' + videoId)
      ).data) as { preSignedUrl: string }
      return preSignedUrl
    } catch (error) {
      console.log(error.message)
      return null
    }
  }
}
