import React, {useContext, useState} from "react";
import {IFirebaseAuthUser} from "../../interfaces/authentication.interfaces";
import withAuthorization from "../HOC/with.authorization";
import {useHistory} from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import AppContext from "../../context/app.context";
import {Result, Button, Alert} from "antd";
import {ReAuthenticationLink} from "../ReAuthenticationPrompt";
import AuthenticationValidatorLogic from "../../logic/authenticationValidatorLogic";
import {IAppContext} from "../../interfaces/context.interfaces";
import Style from "../../styles/Pages.module.css";
import SadHeart from "../Animations/SadHeart";

const DeleteAccountPage = () => {

    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(undefined);

    // state counter to enable refreshing this component
    const [refreshCounter, setRefreshCounter] = useState(0);
    const refresh = () => setRefreshCounter(refreshCounter + 1);

    const appContext: IAppContext = useContext(AppContext);

    if (!appContext?.services?.firebaseService) {
        return null;
    }

    // as long as the user data has not yet been retrieved...
    if ((appContext as any)?.userData == null) {
      // ... refresh this component once a second
      window.setTimeout(refresh, 1000);
      return null;
    }

    let language = (appContext as any)?.userData?.language;

    const onClickNo = () => {
        setError(undefined);
        history.push(ROUTES.ACCOUNT.route);
    };
    const onClickYes = async () => {
        setError(undefined);
        setLoading(true);
        try {
            await appContext.services.userService.deleteUser();
            await appContext.services.firebaseService.deleteUser();

        } catch(error) {
            setError(error?.message || "Error deleting user");
        }
        finally {
            setLoading(false);
        }
    };

    const onCloseError = () => {
        setError(undefined);
    };
    return (
        <div className={Style.page}>
            <div className={Style.formContainer}>
                <Result
                    icon={<SadHeart/>}
                    title={ (language === "de") ? "Sind Sie sich sicher, dass Sie uns verlassen möchten?" : "Are you sure you want to leave us?" }
                    extra={[
                        <Button key={'yes'} loading={loading} onClick={onClickYes}>{ (language === "de") ? "Ja" : "Yes" }</Button>,
                        <Button key={'no'} loading={loading} onClick={onClickNo} type="primary">{ (language === "de") ? "Nein" : "No" }</Button>
                    ]}
                />
                {error && <Alert
                    message={error}
                    description={ReAuthenticationLink()}
                    type="error"
                    closable
                    onClose={onCloseError}
                />}
            </div>
        </div>
    );
};

const condition = (authUser: IFirebaseAuthUser) => AuthenticationValidatorLogic.isAuthenticated(authUser);

export default withAuthorization(condition)(ROUTES.SIGN_IN.route)(DeleteAccountPage);
