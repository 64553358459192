import React, { useContext, useState } from "react";
import Style from "../../styles/Pages.module.css";
import { Button, Table, Icon } from "antd";
import { BUTTON_PROPS } from "../../constants/general";
import AppContext from "../../context/app.context";
import { IAppContext } from "../../interfaces/context.interfaces";
import { IFirebaseAuthUser } from "../../interfaces/authentication.interfaces";
import AuthenticationValidatorLogic from "../../logic/authenticationValidatorLogic";
import * as ROUTES from "../../constants/routes";
import withAuthorization from "../HOC/with.authorization";
import moment from "moment";
import { env } from './../../env';

interface IState {
  reports: any,
  refreshCounter: number,
}

function ReportsPage(Props: IState) {

  // state counter to enable refreshing this component
  const [refreshCounter, setRefreshCounter] = useState(0);
  const refresh = () => setRefreshCounter(refreshCounter + 1);

  const [reports, setReports] = useState<any>(0);

  const appContext: IAppContext = useContext(AppContext);

  // state of the id token we get from firebase
  const [idToken, setIdToken] = useState({
    attemptedToFetch: false,
    token: null,
  });

  /**
   * Fetch the idToken from firebase asynchronously and update it through the overall state,
   * causing whatever depends on it to re-render
   */
  const fetchIdToken = async () => {
    setIdToken({
      attemptedToFetch: true,
      token: null,
    });
    let idTokenVal = await appContext?.services?.firebaseService?.auth?.currentUser?.getIdToken();
    setIdToken({
      attemptedToFetch: true,
      token: idTokenVal,
    });
  };

  // initiate token fetching, but only attempt to do so once, not again and again -
  // once it comes from firebase, the modal will be updated through the state
  if (!idToken.attemptedToFetch) {
    fetchIdToken();
  }

  // Fetching Reports
  const fetchReports = async () => {
    setReports([]);
    let reportVal = await appContext?.services?.reportService.getMyData();
    setReports(reportVal.data);
  };

  if (!reports) {
    fetchReports();
  }

  // as long as the user data has not yet been retrieved...
  if ((appContext as any)?.userData == null) {
    // ... refresh this component once a second
    window.setTimeout(refresh, 1000);
    return null;
  }

  let language = (appContext as any)?.userData?.language;

  const serverUrl = `${env.REACT_APP_SERVER_URL}`;

  let tableRows = [];

  function onChange(pagination, filters, sorter, extra) {
    console.log('params', pagination, filters, sorter, extra);
  }

  const columns: any = [
    {
      title: (language === "de") ? "Name" : "Name",
      dataIndex: 'name',
      key: 'name',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: (language === "de") ? "Datum" : "Date",
      dataIndex: 'date',
      key: 'date',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      defaultSortOrder: "descend"
    },
    {
      title: (language === "de") ? "Herunterladen" : "Download",
      dataIndex: 'download',
      align: 'center'
    }
  ]

  for (let i = 0; i < reports.length; i++) {
    let report = reports[i]?.name;
    let date = reports[i]?.dateAdded;
    let linkTarget = serverUrl + "/report/getreport?reportId=" + report + "&token=" + idToken.token;

    tableRows.push({
      key: i,
      name: report,
      date: date,
      download: [<Button
        className={Style.mapTooltipButton}
        {...BUTTON_PROPS}
        size="small"
      >
        <a href={linkTarget} target="_blank" rel="noopener noreferrer" download>
          {<Icon type="download" />}
        </a>
      </Button>]
    });
  }

  return (

    <div className={Style.formContainer}>
      <h2>{(language === "de") ? "Berichte" : "Reports"}</h2>
      <Table columns={columns} dataSource={tableRows} onChange={onChange} />
    </div>
  )
};

const condition = (authUser: IFirebaseAuthUser) =>
  AuthenticationValidatorLogic.isAuthenticated(authUser);

export default withAuthorization(condition)(ROUTES.SIGN_IN.route)(ReportsPage);