import {
    Form,
    Input,
    Checkbox,
    Button,
    Alert,
    Result,
    Select,
    Spin
} from 'antd';
import { FormComponentProps } from 'antd/es/form';
import debounce from 'lodash/debounce';
import React from 'react';
import { withRouter } from 'react-router-dom';
import {
    NO_NUMBERS_REGEX,
    ONLY_NUMBERS_REGEX,
    PHONE_REGEX,
    tailFormItemLayout
} from "../../constants/signup.consts";
import {ICountry, ISignUpBetaFormValues} from "../../interfaces/form.interfaces";
import {IEmailService, IFirebaseService, IUserService} from "../../interfaces/services.interfaces";
import {withFirebase} from "../HOC/with.firebase";
import {RouteComponentProps} from "react-router";
import {PRIVACY_LINK} from "../../constants/general";
import {MapLink} from "../MapData/MapData";
import CurrentEmailInput from "../CurrentEmail/current.email.input";
import Style from "../../styles/Form.module.css";
import {trimObjectStringValues} from "../../utils/string.utils";

const { Option } = Select;

interface IProps extends FormComponentProps, RouteComponentProps<any> {
    firebase: IFirebaseService;
    emailService: IEmailService;
    userService: IUserService;
}

interface IState {
    error?: string;
    fetching: boolean;
    wasEmailSent: boolean;
    data: any[];
    phonePrefix: string[];
    loading: boolean;
}

const INITIAL_STATE = {
    error: undefined,
    wasEmailSent: false,
    fetching: false,
    data: [],
    phonePrefix: [],
    loading: false,
};

const betaSignUpFormLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
    layout: "vertical" as "vertical",
};

class BetaSignUpForm extends React.Component<IProps, IState> {

    private lastFetchId: number;

    constructor(props: IProps) {
        super(props);
        this.state = {
            ...INITIAL_STATE
        };
        this.lastFetchId = 0;
        this.fetchCountries = debounce(this.fetchCountries, 800, {
            trailing: true,
            maxWait: 3000
        });
    }

    public render() {
        const { form: {getFieldDecorator} } = this.props;
        const { error, wasEmailSent, data, fetching, phonePrefix, loading } = this.state;
        if (wasEmailSent) {
            return (
                <Result
                    status="success"
                    title="Thank you for signing up for the beta!"
                    subTitle="We will get in touch with you soon"
                    extra={[
                        MapLink()
                    ]}
                />
            );
        }

        const prefixSelector = getFieldDecorator('phonePrefix', {})(
            <Select defaultActiveFirstOption={true} style={{ width: 80 }}>
                {
                    phonePrefix.map(prefix => <Option key={prefix} value={prefix}>+{prefix}</Option>)
                }
            </Select>,
        );

        return (
            <>
            <h2>Sign up for free beta to access additional features</h2>
            <Form {...betaSignUpFormLayout} onSubmit={this.handleSubmit}>
                <CurrentEmailInput/>
                <Form.Item>
                    {
                        getFieldDecorator('fullName',
                            {
                                rules: [
                                    {
                                        pattern: NO_NUMBERS_REGEX,
                                        max: 128,
                                        message: "No numbers allowed, max 128 characters"
                                    },
                                    {
                                        required: true,
                                        message: 'Please input your full name',
                                    }
                                ],
                            }
                        )(
                            <div className={Style.inputAndLabelPlaceholder}>
                                <input type="text" name="fullName" id="fullName" placeholder="Your Full Name"/>
                                <label htmlFor="fullName">Full Name</label>
                            </div>
                        )}
                </Form.Item>
                <Form.Item>
                    {
                        getFieldDecorator('preferredName',
                            {
                                rules: [
                                    {
                                        pattern: NO_NUMBERS_REGEX,
                                        max: 128,
                                        message: "No numbers allowed, max 128 characters"
                                    },
                                    {
                                        required: true,
                                        message: 'Please input the name you would like us to address you with',
                                    }
                                ],
                            }
                        )(
                            <div className={Style.inputAndLabelPlaceholder}>
                                <input type="text" name="preferredName" id="preferredName" placeholder="What should we call you?"/>
                                <label htmlFor="preferredName">Preferred Name</label>
                            </div>
                        )}
                </Form.Item>
                <Form.Item hasFeedback>
                    {
                        getFieldDecorator('country',
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please select your country',
                                    }
                                ],
                            }
                        )(<Select
                            placeholder="Start typing to select a country"
                            showSearch={true}
                            labelInValue={true}
                            defaultActiveFirstOption={false}
                            notFoundContent={fetching ? <Spin size="small" /> : null}
                            filterOption={false}
                            onSearch={this.fetchCountries}
                            onChange={this.onCountryChange}
                        >
                            {data.map(country => (
                                <Option key={country.value} value={country.value}>{country.text}</Option>
                            ))}
                        </Select>,)}
                </Form.Item>
                <Form.Item>
                    {
                        getFieldDecorator('phone',
                            {
                                rules: [
                                    {
                                        pattern: PHONE_REGEX,
                                        max: 40,
                                        message: "Not a valid number"
                                    },
                                    {
                                        required: true,
                                        message: 'Please input your phone so we can get in touch with you',
                                    }
                                ],
                            }
                        )(<Input addonBefore={prefixSelector}/>)}
                </Form.Item>
                <Form.Item>
                    {
                        getFieldDecorator('company',
                            {
                                rules: [
                                    {
                                        max: 100,
                                        message: 'max 100 characters',
                                    },
                                    {
                                        required: true,
                                        message: 'Please input the company you are working for',
                                    }
                                ],
                            }
                        )(
                            <div className={Style.inputAndLabelPlaceholder}>
                                <input type="text" name="company" id="company" placeholder="Your Company Name"/>
                                <label htmlFor="company">Company</label>
                            </div>
                        )}
                </Form.Item>
                <Form.Item>
                    {
                        getFieldDecorator('companyType',
                            {
                                rules: [
                                    {
                                        max: 100,
                                        message: 'max 100 characters',
                                    },
                                    {
                                        required: true,
                                        message: 'Please input which type your company belongs to',
                                    }
                                ],
                            }
                        )(
                            <div className={Style.inputAndLabelPlaceholder}>
                                <input type="text" name="companyType" id="companyType" placeholder="Oil & Gas, Earth Observation, Software development, Research institution, Other"/>
                                <label htmlFor="companyType">Company Type</label>
                            </div>
                        )}
                </Form.Item>
                <Form.Item>
                    {
                        getFieldDecorator('position',
                            {
                                rules: [
                                    {
                                        max: 100,
                                        message: 'max 100 characters',
                                    },
                                    {
                                        required: true,
                                        message: 'Please input your position in this company',
                                    }
                                ],
                            }
                        )(
                            <div className={Style.inputAndLabelPlaceholder}>
                                <input type="text" name="position" id="position" placeholder="What is your official title?"/>
                                <label htmlFor="position">Position</label>
                            </div>
                        )}
                </Form.Item>
                <Form.Item>
                    {
                        getFieldDecorator('address',
                            {
                                rules: [
                                    {
                                        max: 95,
                                        message: 'max 95 characters',
                                    },
                                    {
                                        required: true,
                                        message: 'Please input your address',
                                    }
                                ],
                            }
                        )(
                            <div className={Style.inputAndLabelPlaceholder}>
                                <input type="text" name="address" id="address" placeholder="Street and number, P.O. box"/>
                                <label htmlFor="address">Street Address</label>
                            </div>
                        )}
                </Form.Item>
                <Form.Item>
                    {
                        getFieldDecorator('addressOptional', {
                            rules: [
                                {
                                    max: 95,
                                    message: 'max 95 characters',
                                },
                            ],
                            }
                        )(
                            <div className={Style.inputAndLabelPlaceholder}>
                                <input type="text" name="addressOptional" id="addressOptional" placeholder="Apartment, building, floor, etc."/>
                                <label htmlFor="addressOptional">Optional Address Details</label>
                            </div>
                        )}
                </Form.Item>
                <Form.Item>
                    {
                        getFieldDecorator('city',
                            {
                                rules: [
                                    {
                                        max: 35,
                                        message: 'max 35 characters',
                                    },
                                    {
                                        required: true,
                                        message: 'Please input the city you live in',
                                    }
                                ],
                            }
                        )(
                            <div className={Style.inputAndLabelPlaceholder}>
                                <input type="text" name="city" id="city" placeholder="City"/>
                                <label htmlFor="city">City</label>
                            </div>
                        )}
                </Form.Item>
                <Form.Item>
                    {
                        getFieldDecorator('zipCode',
                            {
                                rules: [
                                    {
                                        pattern: ONLY_NUMBERS_REGEX,
                                        message: 'only numbers allowed',
                                    },
                                    {
                                        required: true,
                                        message: 'Please input your zip code',
                                    }
                                ],
                            }
                        )(
                            <div className={Style.inputAndLabelPlaceholder}>
                                <input type="number" name="zipCode" id="zipCode" placeholder="Zip Code"/>
                                <label htmlFor="zipCode">Zip Code</label>
                            </div>
                        )}
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                    {getFieldDecorator('agreement', {
                        initialValue: false,
                        valuePropName: 'checked',
                        rules: [
                            {
                                validator: this.checkAgreement
                            }
                        ]
                    })(
                        <Checkbox>
                            I have read and agree with&nbsp;
                            <a target="_blank" rel="noopener noreferrer" href={PRIVACY_LINK}>
                                the privacy policy
                            </a>
                        </Checkbox>,
                    )}
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                    <Button loading={loading} type="primary" htmlType="submit">
                        Sign Up
                    </Button>
                </Form.Item>
            </Form>
            {MapLink()}
            {error && <Alert
                message={error}
                type="error"
                closable
                onClose={this.onCloseError}
            />}
            </>
        );
    }

    private onCloseError = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      this.setState({error: undefined});
    };

    private handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const { form, emailService, userService } = this.props;
        this.setState({error: undefined});
        form.validateFieldsAndScroll(async (err, values: ISignUpBetaFormValues) => {
            if (!err) {
                try {
                    this.setState({loading: true});
                    trimObjectStringValues(values);
                    await emailService.sendBetaEmail({
                        ...values,
                    });
                    await userService.addData({
                        ...values,
                        betaUser: true,
                    })
                    this.setState({
                        ...INITIAL_STATE,
                        wasEmailSent: true
                    });
                } catch(error) {
                    this.setState({ error: error.message || 'error sending email' });
                } finally {
                    this.setState({loading: false});
                }
            }
        });
    };

    private checkAgreement = (rule: any, value: any, callback: (...args: any[]) => any) => {
        const { form } = this.props;
        if (!form.getFieldValue('agreement')) {
            callback('Must agree to the privacy policy!');
        } else {
            callback();
        }
    };

    private readonly fetchCountries = async (value: string) => {
        this.lastFetchId += 1;
        const fetchId = this.lastFetchId;
        this.setState({ data: [], fetching: true });
        fetch(`https://restcountries.eu/rest/v2/name/${value}`)
            .then(response => response.json())
            .then(body => {
                if (fetchId !== this.lastFetchId) {
                    return;
                }
                const data = body.map((country: any) => ({
                    text: country.name,
                    value: country.alpha3Code,
                    callingCodes: country.callingCodes
                }));
                this.setState({ data, fetching: false });
            })
            .catch(e => this.setState({fetching: false}));
    };

    private onCountryChange = (countryValue: ICountry) => {
        const { data } = this.state;
        const { form } = this.props;
        const country = data.find((country: {text: string, value: string, callingCodes: string[]}) => country.value === countryValue?.key);
        const phonePrefix: string[] = (country?.callingCodes || []);
        phonePrefix.length && form.setFieldsValue({
            phonePrefix: phonePrefix[0],
        });
        this.setState({
            data: [],
            fetching: false,
            phonePrefix
        });
    }
}

export default withFirebase(withRouter((Form.create<IProps>({ name: 'betaSignUp' })(BetaSignUpForm))));
