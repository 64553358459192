import { IReportService, IFirebaseService } from "../interfaces/services.interfaces";
import AbstractService from './abstract.service';
import { env } from './../env';

export default class ReportService extends AbstractService implements IReportService {

  constructor({ firebaseService }: { firebaseService: IFirebaseService }) {
    super({ firebaseService });
  }

  public async getMyData(): Promise<any> {
    const url = `${env.REACT_APP_SERVER_URL}/report/my_data`;
    return await this.axiosInstance.get(url);
  }

}


