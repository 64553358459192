import {
  IDroneService,
  IFirebaseService,
} from "../interfaces/services.interfaces";
import AbstractService from "./abstract.service";
import { env } from "../env";
import {
  IDroneImage,
  IDroneJob,
  IDronePath,
} from "../interfaces/map.interfaces";

export default class DroneService
  extends AbstractService
  implements IDroneService
{
  constructor({ firebaseService }: { firebaseService: IFirebaseService }) {
    super({ firebaseService });
  }

  public async getDroneJobs(projectId: string): Promise<{ data: IDroneJob[] }> {
    const url = `${env.REACT_APP_SERVER_URL}/drone/jobs`;
    return await this.axiosInstance.get(url, { params: { projectId } });
  }

  public async getDronePaths(
    projectId: string,
    jobId: string
  ): Promise<{ data: IDronePath[] }> {
    const url = `${env.REACT_APP_SERVER_URL}/drone/paths`;
    return await this.axiosInstance.get(url, { params: { projectId, jobId } });
  }

  public async getImages(projectId: string): Promise<{ data: IDroneImage[] }> {
    const url = `${env.REACT_APP_SERVER_URL}/drone/images`;
    return await this.axiosInstance.get(url, { params: { projectId } });
  }

  public async getImageUrl(key: string): Promise<{ data: string }> {
    const url = `${env.REACT_APP_SERVER_URL}/drone/getImageUrl`;
    return await this.axiosInstance.get(url, { params: { key } });
  }
}
