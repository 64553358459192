import React, {useContext} from 'react';
import * as ROUTES from "../../constants/routes";
import {IFirebaseAuthUser} from "../../interfaces/authentication.interfaces";
import withAuthorization from "../HOC/with.authorization";
import AuthenticationValidatorLogic from "../../logic/authenticationValidatorLogic";
import BetaSignUpForm from './beta.signup.form';
import AppContext from "../../context/app.context";
import Style from '../../styles/Pages.module.css';

const BetaSignUpPage = () => {
    const appContext = useContext(AppContext);
    return (
        <div className={Style.page}>
            <div className={Style.formContainer}>
                <BetaSignUpForm
                    emailService={appContext.services.emailService}
                    userService={appContext.services.userService}
                />
            </div>
        </div>
    );
};

const condition = (authUser: IFirebaseAuthUser) => AuthenticationValidatorLogic.isAuthenticated(authUser);
export default withAuthorization(condition)(ROUTES.BETA_SIGN_UP.route)(BetaSignUpPage);
