import { Form, Checkbox, Button, Alert, Result, Modal } from "antd";
import { FormComponentProps } from "antd/es/form";
import React from "react";
import { withRouter } from "react-router-dom";
import {
  formItemLayout,
  tailFormItemLayout,
} from "../../constants/signup.consts";
import { IRegisterFormValues } from "../../interfaces/form.interfaces";
import {
  IUserService,
  IFirebaseService,
} from "../../interfaces/services.interfaces";
import { withFirebase } from "../HOC/with.firebase";
import { RouteComponentProps } from "react-router";
import { EULA_LINK } from "../../constants/general";
import AuthenticationValidatorLogic from "../../logic/authenticationValidatorLogic";
import Style from "../../styles/Form.module.css";
import { LoginLink } from "../Login";
import { WarningOutlined } from "@ant-design/icons";

interface IProps extends FormComponentProps, RouteComponentProps<any> {
  firebase: IFirebaseService;
  userService: IUserService;
}

interface IState {
  confirmDirty: boolean;
  error?: string;
  wasEmailVerificationSent: boolean;
  loading: boolean;
}

const INITIAL_STATE = {
  confirmDirty: false,
  error: undefined,
  wasEmailVerificationSent: false,
  loading: false,
};

class RegistrationForm extends React.Component<IProps, IState> {
  state = {
    ...INITIAL_STATE,
  };

  public render() {
    const {
      form: { getFieldDecorator },
    } = this.props;
    const { error, wasEmailVerificationSent, loading } = this.state;
    if (wasEmailVerificationSent) {
      return (
        <Result
          status="success"
          title="You have successfully Signed up!"
          subTitle="A verification email will be waiting in your inbox."
        />
      );
    }

    return (
      <>
        <h1 className={Style.formHeading}>
          Monitor gas pipelines for risks and deliver warnings directly to your
          desk
        </h1>
        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
          <Form.Item>
            {getFieldDecorator("email", {
              rules: [
                {
                  type: "email",
                  message: "The input is not valid E-mail, no whitespaces",
                },
                {
                  required: true,
                  message: "Please input your E-mail",
                },
              ],
            })(
              <div className={Style.inputAndLabelPlaceholder}>
                <input
                  type="text"
                  name="email"
                  id="email"
                  placeholder="mail@example.com"
                />
                <label htmlFor="email">Email</label>
              </div>
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("password", {
              rules: [
                {
                  required: true,
                  message: "Please input your password",
                },
                {
                  validator:
                    AuthenticationValidatorLogic.validateStrongPassword,
                },
                {
                  validator: this.validateToNextPassword,
                },
              ],
            })(
              <div className={Style.inputAndLabelPlaceholder}>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Start typing"
                />
                <label htmlFor="password">Password</label>
              </div>
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("confirm", {
              rules: [
                {
                  required: true,
                  message: "Please confirm your password",
                },
                {
                  validator: this.compareToFirstPassword,
                },
              ],
            })(
              <div className={Style.inputAndLabelPlaceholder}>
                <input
                  onBlur={this.handleConfirmBlur}
                  type="password"
                  name="confirm"
                  id="confirm"
                  placeholder="Repeat password"
                />
                <label htmlFor="confirm">Confirm Password</label>
              </div>
            )}
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            {getFieldDecorator("agreement", {
              initialValue: false,
              valuePropName: "checked",
              rules: [
                {
                  validator: this.checkAgreement,
                },
              ],
            })(
              <Checkbox>
                I have read the{" "}
                <a target="_blank" rel="noopener noreferrer" href={EULA_LINK}>
                  Terms and Conditions
                </a>
              </Checkbox>
            )}
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Button
              loading={loading}
              className={Style.formActionButton}
              type="primary"
              htmlType="submit"
            >
              Try Our Demo Now
            </Button>
          </Form.Item>
        </Form>
        {LoginLink()}
        <div className={Style.errorContainer}>
          {error && (
            <Alert
              message={error}
              type="error"
              closable
              onClose={this.onCloseRegistrationError}
            />
          )}
        </div>
      </>
    );
  }

  private onCloseRegistrationError = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    this.setState({ error: undefined });
  };

  private handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    //-----Comment code below to enable registration-----
    
    // Modal.warning({
    //   icon: <WarningOutlined style={{ fontSize: 32 }} />,
    //   title: <h1 style={{ color: "white" }}>SVS Demo is Closed</h1>,
    //   content: (
    //     <p style={{ color: "white", fontSize: 16 }}>
    //       There is currently no open registration for the SVS GIS. Please
    //       contact{" "}
    //       <a href="mailto:business@supervision.earth">
    //         business@supervision.earth
    //       </a>{" "}
    //       to get access to the demo
    //     </p>
    //   ),
    // });
    // return;

    // ---Uncomment code below to enable registration---

    const { form, firebase, userService } = this.props;
    this.setState({ error: undefined });
    form.validateFieldsAndScroll(async (err, values: IRegisterFormValues) => {
      if (err) {
        return;
      }
      const { email, password } = values;
      try {
        this.setState({ loading: true });
        await firebase.signUpUsingEmailPassword(email.trim(), password);
        this.setState({
          ...INITIAL_STATE,
          wasEmailVerificationSent: true,
        });
        await userService.signin();
      } catch (error) {
        this.setState({
          error: error.message || "error signing up to the auth service",
        });
      } finally {
        this.setState({ loading: false });
      }
    });
  };

  private handleConfirmBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const { confirmDirty } = this.state;
    this.setState({ confirmDirty: confirmDirty || !!value });
  };

  private compareToFirstPassword = (
    rule: any,
    value: any,
    callback: (...args: any[]) => any
  ) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("The passwords that you entered do not match");
    } else {
      callback();
    }
  };

  private validateToNextPassword = (
    rule: any,
    value: any,
    callback: (...args: any[]) => any
  ) => {
    const { form } = this.props;
    const { confirmDirty } = this.state;
    if (value && confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  private checkAgreement = (
    rule: any,
    value: any,
    callback: (...args: any[]) => any
  ) => {
    const { form } = this.props;
    if (!form.getFieldValue("agreement")) {
      callback("Must agree to the end user terms and agreement");
    } else {
      callback();
    }
  };
}

export default withFirebase(
  withRouter(Form.create<IProps>({ name: "register" })(RegistrationForm))
);
