import React, {PropsWithChildren, ReactNode, useEffect, useState} from "react";
import Style from './PublicPage.module.css';
import Spinner from "../Animations/Spinner";

interface IProps {
    imageSource: string;
}

export const PublicPageWithForm = (props: PropsWithChildren<IProps>) => {
    const [source, setSource] = useState<ReactNode | null>(null);

    useEffect(() => {
        const img = new Image();
        img.src = props.imageSource;
        img.onload = () => setSource(props.imageSource)
    }, [props.imageSource]);

    if (!source) {
        return <Spinner/>
    }
    return (
        <div className={Style.publicPageImage}>
            <div className={Style.formContainer}>
                {props.children}
            </div>
        </div>
    );
};
