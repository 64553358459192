import {Link} from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import React from "react";
import {PasswordForgetForm} from "./forgot.password.form";
import {IFirebaseAuthUser} from "../../interfaces/authentication.interfaces";
import withAuthorization from "../HOC/with.authorization";
import AuthenticationValidatorLogic from "../../logic/authenticationValidatorLogic";
import {PublicPageWithForm} from "../PublicPageWithForm/publicPageWithForm";
import EventImage from "../../assets/landing_page.jpg";

const PasswordForgetPage = () => (
    <PublicPageWithForm imageSource={EventImage}>
        <PasswordForgetForm />
    </PublicPageWithForm>
);

const PasswordForgetLink = () => (
    <Link to={ROUTES.PASSWORD_FORGET.route}>{ROUTES.PASSWORD_FORGET.Label}</Link>
);

export { PasswordForgetLink };

const condition = (authUser: IFirebaseAuthUser) => !AuthenticationValidatorLogic.isAuthenticated(authUser);
export default withAuthorization(condition)(ROUTES.MAP.route)(PasswordForgetPage);
