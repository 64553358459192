import {
  IFirebaseService,
  ISegmentService,
} from "../interfaces/services.interfaces";
import AbstractService from "./abstract.service";
import { env } from "../env";

export default class SegmentService
  extends AbstractService
  implements ISegmentService
{
  constructor({ firebaseService }: { firebaseService: IFirebaseService }) {
    super({ firebaseService });
  }

  public async getMyData(projectId: string): Promise<any> {
    const url = `${env.REACT_APP_SERVER_URL}/segment/my_data`;
    return await this.axiosInstance.get(url, { params: { projectId } });
  }
}
