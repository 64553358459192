import React, { PropsWithChildren, useContext } from "react";
import MapComponentContext from "./map.component.context";
import Style from "./Map.module.css";
import { Icon } from "antd";

interface IProps {
	// no longer necessary, as left and top are now fixed to the constant 10
	// rather than being set dynamically
	position: {
		x: number;
		y: number;
	};
}

export default function MapTooltip(props: PropsWithChildren<IProps>) {
	const mapContext = useContext(MapComponentContext);

	if (!mapContext) {
		return null;
	}

	const { children } = props;

	return (
		<div
			className={Style.mapTooltipContainer}
			style={{
				left: 10,
				top: 10,
				bottom: 10,
				overflow: "auto",
			}}>
			<div className={Style.mapTooltip}>
				<span
					onClick={mapContext?.closeTooltip}
					className={Style.mapCloseTooltip}>
					<Icon type="close" />
				</span>
				<br />
				{children}
			</div>
		</div>
	);
}
