export const MAP_ACTIVE_ICON = "active";
export const MAP_ONLY_REPORT_CHANGE_ICON = "only report change";
export const MAP_DO_NOT_REPORT_ICON = "do not report";
export const MAP_METHANE_ICON = "methane";
export const MAP_VEG_ICON = "Vegetation"
export const MAP_DRIVING_ICON = "driving";
export const EVENT = "event";
export const MAP_HELI_EVENT_ICON = "heli event";
export const MAP_HELI_EVENT_ACTIVE_ICON = "heli event report";
export const MAP_HELI_ONLY_REPORT_CHANGE_ICON = "only report change";
export const MAP_DRONE_EVENT_ICON = "Drone event";
export const MAP_DRONE_EVENT_ACTIVE_ICON = "Drone event report";
export const MAP_SAT_EVENT_ICON = "Satellite event";
export const MAP_SAT_EVENT_ACTIVE_ICON = "Satellite event report";
export const INCIDENT_ICON_SIZE: number = 8;
export const PIPELINE_COLOR_PRIMARY: number[] = [255, 0, 42];
export const PIPELINE_COLOR_SECONDARY: number[] = [4, 160, 252];
export const SEGMENT_COLOR: number[] = [0, 255 / 2, 255];
export const GRID_AREA_COLOR: number[] = [0, 0, 255];
export const PIPELINE_HIGHLIGHT_COLOR: number[] = [255, 96, 128, 222];
export const PIPELINE_HIGHLIGHT_COLOR_2: number[] = [4, 180, 252];
export const DEFAULT_MAP_VIEW_PROPS = {
  zoom: 8,
  longitude: 8.678555,
  latitude: 49.862156,
};
