import React, {Component, ReactNode} from 'react';
import {Alert, Button, Form} from "antd";
import { FormComponentProps, ValidationRule } from 'antd/es/form';
import {
    IFieldFormValues,
} from "../../interfaces/form.interfaces";
import {FIREBASE_UNIQUE_ERROR_CODES} from "../../constants/firebase.consts";
import {ReAuthenticationLink} from "../ReAuthenticationPrompt";
import {capitalize} from "../../utils/string.utils";
import {AccountSettingsLink} from "../Account";
import {formItemLayout, tailFormItemLayout} from "../../constants/signup.consts";
import FormStyles from '../../styles/Form.module.css'

interface IProps extends FormComponentProps {
    Extra?: React.ComponentType<any>;
    fieldName: string;
    fieldValidators: Array<ValidationRule>;
    fieldNodes: (key: string) => Array<ReactNode>;
    fieldMethod: (field: string) => Promise<any>;
    SuccessResult: React.ComponentType<any>;
    formHeading: string;
}

interface IState {
    error?: string;
    wasOperationSuccessful: boolean;
    confirmDirty: boolean;
    renderReAuthenticationPrompt: boolean;
    loading: boolean;
    refreshCounter: number;
}

const INITIAL_STATE = {
    error: undefined,
    wasOperationSuccessful: false,
    confirmDirty: false,
    renderReAuthenticationPrompt: false,
    loading: false,
};

class ChangeFieldFormBase extends Component<IProps, IState> {

    private language: string;


    constructor(props: any, context: any) {

        super(props);

        this.state = {
          ...INITIAL_STATE,
          refreshCounter: 0,
        };
    }

    public render() {

        // state counter to enable refreshing this component
        const refresh = () => this.setState({refreshCounter: this.state.refreshCounter + 1});

        // as long as the user data has not yet been retrieved...
        if ((this.context as any)?.userData == null) {
          // ... refresh this component once a second
          window.setTimeout(refresh, 1000);
        }

        let language = (this.context as any)?.userData?.language;
        this.language = language;

        const {
            form: {getFieldDecorator},
            fieldName,
            Extra,
            fieldValidators,
            fieldNodes,
            SuccessResult,
            formHeading,
        } = this.props;
        const {error, wasOperationSuccessful, renderReAuthenticationPrompt, loading} = this.state;
        if (wasOperationSuccessful) {
            return (
                <SuccessResult/>
            );
        }
        return (
            <>
                <h2 className={FormStyles.formContainer}>{formHeading}</h2>
                <Form onSubmit={this.handleSubmit} {...formItemLayout}>
                    {Extra && <Extra/>}
                    <Form.Item>
                        {getFieldDecorator('value', {
                            rules: [
                                {
                                    required: true,
                                    message: ((language === "de" ) ? "Bitte gib deine " + fieldName + " ein!" : "Please input your " + fieldName + "!" ),
                                },
                                {
                                    validator: this.validateToNextField,
                                },
                                ...fieldValidators
                            ],
                        })(fieldNodes('value')[0])}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('confirm', {
                            rules: [
                                {
                                    required: true,
                                    message: ((language === "de" ) ? "Bitte bestätige deine " + fieldName + "!" : "Please confirm your " + fieldName + "!" ),
                                },
                                {
                                    validator: this.compareToFirstField,
                                },
                                ...fieldValidators
                            ],
                        })(fieldNodes('confirm')[1])}
                    </Form.Item>
                    <Form.Item {...tailFormItemLayout}>
                        <Button loading={loading} type="primary" htmlType="submit">
                            Submit {capitalize(fieldName)}
                        </Button>
                    </Form.Item>
                </Form>
                <div className={FormStyles.errorContainer}>
                    {error && <Alert
                        message={error}
                        description={renderReAuthenticationPrompt && ReAuthenticationLink()}
                        type="error"
                        closable
                        onClose={this.onCloseError}
                    />}
                </div>
                {AccountSettingsLink()}
            </>
        );
    }

    private onCloseError = () => {
        this.setState({...INITIAL_STATE});
    };

    private compareToFirstField = (rule: any, value: any, callback: (...args: any[]) => any) => {
        const { form, fieldName } = this.props;
        if (value && value !== form.getFieldValue('value')) {
            callback((this.language === "de" ) ?
                "Die eingegebenen " + fieldName + " sind nicht gleich!" :
                "The " + fieldName + "s that you entered do not match!"
            );
        } else {
            callback();
        }
    };

    private validateToNextField = (rule: any, value: any, callback: (...args: any[]) => any) => {
        const { form } = this.props;
        const { confirmDirty } = this.state;
        if (value && confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    };

    private handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const { form, fieldMethod } = this.props;
        this.setState({...INITIAL_STATE});
        form.validateFieldsAndScroll(async (err, values: IFieldFormValues) => {
            if (!err) {
                const { value } = values;
                try {
                    this.setState({loading: true});
                    await fieldMethod(value);
                    this.setState({
                        ...INITIAL_STATE,
                        wasOperationSuccessful: true
                    });
                } catch(error) {
                    if (error?.code?.toLocaleLowerCase() === FIREBASE_UNIQUE_ERROR_CODES.REQUIRES_RECENT_LOGIN) {
                        this.setState({renderReAuthenticationPrompt: true});
                    }
                    this.setState({ error: error.message || ((this.language === "de" ) ? "Ein Fehler ist aufgetreten" : "Error occured") });
                } finally {
                    this.setState({loading: false});
                }
            }
        });
    };
}
const ChangeFieldForm = Form.create<IProps>({ name: 'field_form' })(ChangeFieldFormBase);
export { ChangeFieldForm };
