import { IUserService, IFirebaseService } from "../interfaces/services.interfaces";
import AbstractService from './abstract.service';
import { env } from './../env';

export default class UserService extends AbstractService implements IUserService {

    constructor({ firebaseService }: { firebaseService: IFirebaseService }) {
        super({ firebaseService });
    }

    public async signin(): Promise<void> {
        const url = `${env.REACT_APP_SERVER_URL}/user/signin`;
        await this.axiosInstance.post(url, {});
    }

    public async addData(data: any): Promise<void> {
        const url = `${env.REACT_APP_SERVER_URL}/user/add_data`;
        await this.axiosInstance.post(url, data);
    }

    public async getMyData(): Promise<any> {
        const url = `${env.REACT_APP_SERVER_URL}/user/my_data`;
        return await this.axiosInstance.get(url);
    }

    public async deleteUser(): Promise<void> {
        const url = `${env.REACT_APP_SERVER_URL}/user/delete_user`;
        return await this.axiosInstance.get(url);
    }
}
