import { LinearInterpolator, TRANSITION_EVENTS } from "deck.gl";
import {
  MAP_ACTIVE_ICON,
  MAP_DO_NOT_REPORT_ICON,
  MAP_DRIVING_ICON,
  MAP_DRONE_EVENT_ACTIVE_ICON,
  MAP_DRONE_EVENT_ICON,
  MAP_HELI_EVENT_ACTIVE_ICON,
  MAP_HELI_EVENT_ICON,
  MAP_HELI_ONLY_REPORT_CHANGE_ICON,
  MAP_METHANE_ICON,
  MAP_ONLY_REPORT_CHANGE_ICON,
  MAP_SAT_EVENT_ACTIVE_ICON,
  MAP_SAT_EVENT_ICON,
  MAP_VEG_ICON,
} from "../../constants/map.consts";
import { IEvent, ILocation, IViewState } from "../../interfaces/map.interfaces";
import { ICompareImages } from "./deck.gl.map";
import moment from "moment";

export const getUpdatesImageComparisonEvents = function (
  location: ILocation,
  date,
  eventID
): ICompareImages {
  let events = location.events;
  let evBefore = null;
  let evAfter = null;
  let eventAfterIndex = null;
  let eventBeforeIndex = null;
  let sliderController = 2;

  let evAfterid = Number(eventID.split("_")[4]);

  for (let i = 0; i < events.length; i++) {
    if (
      events[i].properties.image &&
      moment(date).isSame(events[i].properties.date)
    ) {
      // console.log(events[i].properties.image)
      eventAfterIndex = i;
      break;
    }
  }

  for (let i = 0; i < events.length; i++) {
    if (events[i].properties.image) {
      let evBeforeid = Number(events[i].properties.id.split("_")[4]);
      if (evAfterid > evBeforeid) {
        eventBeforeIndex = i;
        break;
      }
    }
  }

  if (!eventBeforeIndex) {
    eventBeforeIndex = eventAfterIndex;
  }

  if (events.length > eventBeforeIndex) {
    evBefore = events[eventBeforeIndex]?.properties;
  }

  if (events.length > eventAfterIndex) {
    evAfter = events[eventAfterIndex]?.properties;
  }

  if (!evAfter?.image) {
    if (evBefore?.image) {
      evAfter = evBefore;
    }
  }

  if (!evBefore?.image) {
    if (evAfter?.image) {
      evBefore = evAfter;
    }
  }

  if (evAfter?.id === evBefore?.id) {
    sliderController = 0;
  }

  console.log(evAfter);
  console.log(evBefore);

  return {
    eventAfter: evAfter,
    eventBefore: evBefore,
    sliderController: sliderController,
  };
};

export const getImageComparisonEvents = function (
  location: ILocation
): ICompareImages {
  let events = location.events;
  let evBefore = null;
  let evAfter = null;
  let eventAfterIndex = null;
  let eventBeforeIndex = null;
  let sliderController = 2;

  // console.log(events)

  for (let i = 0; i < events.length; i++) {
    if (events[i].properties.image) {
      // console.log(events[i].properties.image)
      eventAfterIndex = i;
      break;
    }
  }
  if (eventAfterIndex || eventAfterIndex === 0) {
    for (let i = eventAfterIndex + 1; i < events.length; i++) {
      if (events[i].properties.image) {
        // console.log(events[i].properties.image)
        eventBeforeIndex = i;
        break;
      }
    }
  }

  if (!eventBeforeIndex) {
    eventBeforeIndex = eventAfterIndex;
  }

  if (events.length > eventBeforeIndex) {
    evBefore = events[eventBeforeIndex]?.properties;
  }

  if (events.length > eventAfterIndex) {
    evAfter = events[eventAfterIndex]?.properties;
  }

  if (!evAfter?.image) {
    if (evBefore?.image) {
      evAfter = evBefore;
    }
  }

  if (!evBefore?.image) {
    if (evAfter?.image) {
      evBefore = evAfter;
    }
  }

  if (evAfter?.id === evBefore?.id) {
    sliderController = 0;
  }

  return {
    eventAfter: evAfter,
    eventBefore: evBefore,
    sliderController: sliderController,
  };
};

export const getLocationToShow = function (
  locationsData: ILocation[]
): ILocation {
  // if we are supposed to show a particular location, do so:
  let args = window.location.search.replace("?", "").split("&");
  let doShowLocation = null;
  for (let arg of args) {
    let argPair = arg.split("=");
    if (argPair[0] === "location") {
      doShowLocation = argPair[1];
    }
  }
  if (doShowLocation) {
    for (const eventData of locationsData) {
      if (doShowLocation === eventData.id) {
        return eventData;
      }
    }
  }
  return null;
};

export const zoomIntoEvent = (event: IEvent, viewState: IViewState) => {
  let futureViewState = {
    ...viewState,
    longitude: event.coordinates[0],
    latitude: event.coordinates[1],
    zoom: 17,
    pitch: 0,
    bearing: 0,
    transitionInterpolator: new LinearInterpolator(),
    transitionInterruption: TRANSITION_EVENTS.BREAK,
    transitionEasing: ourEasing,
  };
  futureViewState.transitionDuration = calcDuration(viewState, futureViewState);
  return futureViewState;
};

export const ourEasing = function (val) {
  return -(Math.cos(Math.PI * val) - 1) / 2;
};

export const calcDuration = function (
  prevState: IViewState,
  newState: IViewState
) {
  let result = 800;
  result += Math.abs((newState.zoom - prevState.zoom) * 200);
  return result;
};

export const flyToEvent = (event: IEvent, prevViewState: IViewState) => {
  const newViewState: IViewState = {
    ...prevViewState,
    longitude: event.coordinates[0],
    latitude: event.coordinates[1],
    pitch: 0,
    bearing: 0,
    transitionInterpolator: new LinearInterpolator(),
    transitionInterruption: TRANSITION_EVENTS.BREAK,
    transitionEasing: ourEasing,
  };
  newViewState.transitionDuration = calcDuration(prevViewState, newViewState);
  return newViewState;
};

// export const getEventIcon = (d: IEvent): string => {

//   if(d.properties.id.includes('_i_') && d?.properties?.type === 'Vegetation'){
//     return MAP_VEG_ICON
//   }

//   if (d.properties.id.includes('_h_')) {
//     if (d?.properties?.state === 'active') {
//       return MAP_HELI_EVENT_ACTIVE_ICON
//     }else if (d?.properties?.state === 'only report change') {
//       return MAP_HELI_ONLY_REPORT_CHANGE_ICON
//     }else {
//       return MAP_HELI_EVENT_ICON
//     }
//   }
//   if (d.properties.id.includes('_d_')) {
//     if (d?.properties?.state === 'active') {
//       return MAP_DRONE_EVENT_ACTIVE_ICON
//     }else if (d?.properties?.state === 'only report change') {
//       return MAP_ONLY_REPORT_CHANGE_ICON
//     }else {
//       return MAP_DRONE_EVENT_ICON
//     }
//   }if (d.properties.id.includes('_s_')) {
//     if (d?.properties?.state === 'active') {
//       return MAP_SAT_EVENT_ACTIVE_ICON
//     }else if (d?.properties?.state === 'only report change') {
//       return MAP_ONLY_REPORT_CHANGE_ICON
//     }else {
//       return MAP_SAT_EVENT_ICON
//     }
//   }

//   if (d?.properties?.sourcetype === 'driving') {
//     return MAP_DRIVING_ICON
//   }
//   if (d?.properties?.sourcetype === "ABB") {
//     return MAP_METHANE_ICON;
//   }
//   if (d?.properties?.state === "only report change") {
//     return MAP_ONLY_REPORT_CHANGE_ICON;
//   }
//   if (d?.properties?.state === "do not report") {
//     return MAP_DO_NOT_REPORT_ICON;
//   }
//   return MAP_ACTIVE_ICON;
// };

export const getEventIconColorMapping = (d: IEvent): number[] => {
  if (d?.properties?.state === "only report change") {
    return [255, 205, 0, 255];
  }
  if (d?.properties?.state === "do not report") {
    return [169, 169, 169, 255];
  }
  return [0, 0, 0, 255];
};

export const copyToClipboard = (text: string) => {
  const el = document.createElement("textarea");
  el.value = text;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

const icons = require.context("../../assets/icons", true, /\.(png|jpe?g)$/);

const iconMap = icons.keys().reduce((acc, path) => {
  const key = path.replace("./", "").replace(/\.(png|jpe?g)$/, "");
  acc[key.split("/").reverse()[0]] = icons(path);
  return acc;
}, {});

const stateKeyMap = {
  active: "RED",
  new: "RED",
  "only report change": "YELLOW",
  "do not report": "GREY",
};
const eventIconKey = (key: string, author: string) =>
  author.endsWith("@supervision.earth") ? `${key}_i` : key;

export const getEventIcon = (
  id: string,
  state: string,
  type: string,
  author: string
) => {
  if (type === "Vegetation" && id.includes("_i_")) {
    return iconMap[eventIconKey("GREEN", "")];
  }
  const key = stateKeyMap[state];
  if (id.includes("_d_")) {
    return iconMap[eventIconKey(`${key}_D`, author)];
  } else if (id.includes("_h_")) {
    return iconMap[eventIconKey(`${key}_H`, author)];
  } else if (id.includes("_s_")) {
    return iconMap[eventIconKey(`${key}_S`, author)];
  } else {
    return iconMap[eventIconKey(key, author)];
  }
};
