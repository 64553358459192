import { IPipelineService, IFirebaseService } from "../interfaces/services.interfaces";
import AbstractService from './abstract.service';
import { env } from './../env';

export default class PipelineService extends AbstractService implements IPipelineService {

  constructor({ firebaseService }: { firebaseService: IFirebaseService }) {
    super({ firebaseService });
  }

  public async getMyData(): Promise<any> {
    const url = `${env.REACT_APP_SERVER_URL}/pipeline/my_data`;
    return await this.axiosInstance.get(url);
  }

  public async addPipeline(newPipeline: any): Promise<void> {
    const url = `${env.REACT_APP_SERVER_URL}/pipeline/add`;
    await this.axiosInstance.post(url, newPipeline);
  }

  public async delete(idToDelete: string): Promise<void> {
    const url = `${env.REACT_APP_SERVER_URL}/pipeline/delete`;
    return await this.axiosInstance.post(url, { pipelineId: idToDelete });
  }

}
