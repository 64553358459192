import { Icon, Modal, Upload, message } from "antd";
import type { UploadFile, RcFile } from "antd/lib/upload/interface";
import React, { useEffect, useState } from "react";

const getBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);

		reader.onload = () => resolve(reader.result);

		reader.onerror = (error) => reject(error);
	});

const UploadImage = ({ setFile, file }: { setFile: Function; file: File }) => {
	const [previewVisible, setPreviewVisible] = useState(false);
	const [previewImage, setPreviewImage] = useState("");
	const [previewTitle, setPreviewTitle] = useState("");
	const [fileList, setFileList] = useState<UploadFile[]>([]);

	useEffect(() => {
		if (file) return;
		setFileList([]);
	}, [file]);

	const handleCancel = () => setPreviewVisible(false);

	const handlePreview = async (file) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}

		setPreviewImage(file.url || file.preview);
		setPreviewVisible(true);
		setPreviewTitle(
			file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
		);
	};

	const handleChange = async ({ fileList: newFileList }) => {
		setFileList(newFileList);
		if (newFileList.length) {
			setFileList((prevState) => {
				let newState = [...prevState];
				newState[0].status = "done";
				return newState;
			});
			setFile(newFileList[0]);
		}
	};

	const beforeUpload = (file: RcFile) => {
		const isJpgOrPngOrBmp =
			file.type === "image/jpeg" ||
			file.type === "image/png" ||
			file.type === "image/bmp";
		if (!isJpgOrPngOrBmp) {
			message.error("You can only upload JPG/PNG/BMP file!");
		}
		const isLt10M = file.size / 1024 / 1024 < 10;
		if (!isLt10M) {
			message.error("Image must smaller than 10MB!");
		}
		return isJpgOrPngOrBmp && isLt10M;
	};

	const uploadButton = (
		<div>
			<Icon type="plus" />
			<div
				style={{
					marginTop: 8,
				}}>
				Upload
			</div>
		</div>
	);
	return (
		<>
			<Upload
				name={fileList[0]?.name}
				beforeUpload={beforeUpload}
				listType="picture-card"
				fileList={fileList}
				onPreview={handlePreview}
				onChange={handleChange}>
				{fileList.length > 0 ? null : uploadButton}
			</Upload>
			<Modal
				visible={previewVisible}
				title={previewTitle}
				footer={null}
				onCancel={handleCancel}>
				<img
					style={{
						width: "100%",
					}}
					src={previewImage}
				/>
			</Modal>
		</>
	);
};

export default UploadImage;
