import {
  IEvent,
} from "../interfaces/map.interfaces";
import { message } from "antd";


export async function exportEventToJsonWithMessage(event: IEvent) {
  const hide = message.loading("Export in progress...", 0);
  await exportEventToJson(event);
  hide();
}

export async function exportEventToJson(event: IEvent) {

  const objectData = {
    type: "Feature",
    properties: event.properties,
    geometry: {
      type: "Point",
      coordinates: event.coordinates,
    },
  };

  let filename =  event.properties.id + ".json";
  let contentType = "application/json;charset=utf-8;";

  // we indent each level with 2 whitespaces
  let indentationLevels = 2;
  let jsonString = JSON.stringify(objectData, null, indentationLevels);

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    const blob = new Blob([decodeURIComponent(encodeURI(jsonString))], { type: contentType });
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const a = document.createElement('a');
    a.download = filename;
    a.href = 'data:' + contentType + ',' + encodeURIComponent(jsonString);
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}

export async function exportEventsToCSV(events) {
    let newEventsArr = []
    for (let i = 0; i < events.length; i++){
      let newEvent = {
        "id" : events[i]["id"],
        "locationId" : events[i]["locationId"],
        "type" : events[i]["type"],
        "comment" : events[i]["comment"],
        "state" : events[i]["state"],
        "date" : events[i]["date"],
        "author" : events[i]["author"],
        "projectId" : events[i]["projectId"],
        "confidence" : events[i]["confidence"],
      }
      newEventsArr.push(newEvent)
    }
  
    const array = [Object.keys(newEventsArr[0])].concat(newEventsArr)
  
    let data = array.map(it => {
      return Object.values((it === null)? "" : it).join("; ")
    }).join('\n')
  
    let hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(data);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'output.csv';
    hiddenElement.click();
}