import {Result} from "antd";
import React from "react";
import {ResultStatusType} from "antd/lib/result";

interface IResultProps {
    status: ResultStatusType;
    title: React.ReactNode;
    extra?: React.ReactNode;
    className?: string;
}

export const ResultPageAbstract = (props: IResultProps) => {
    const { status, title, extra, className } = props;
    return (
        <Result
            status={status}
            title={title}
            extra={extra}
            className={className}
        />
    );
};
