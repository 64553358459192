import Style from "../../styles/Pages.module.css";
import {Button, Input, Alert} from "antd";
import React, {ChangeEvent, useContext, useState} from "react";
import CurrentEmailInput from "../CurrentEmail/current.email.input";
import {BUTTON_PROPS} from "../../constants/general";
import AppContext from "../../context/app.context";
import {ResultPageAbstract} from "../Result/resultPageAbstract";
import { IAppContext } from "../../interfaces/context.interfaces";
import { IFirebaseAuthUser } from "../../interfaces/authentication.interfaces";
import AuthenticationValidatorLogic from "../../logic/authenticationValidatorLogic";
import * as ROUTES from "../../constants/routes";
import withAuthorization from "../HOC/with.authorization";

const { TextArea } = Input;

interface IProps {
    onCloseModal: () => void;
    visible: boolean;
}

function FeedbackPage(props: IProps) {

    const [ error, setError ] = useState<string | undefined>(undefined);
    const [ feedbackSent, setFeedbackSent ] = useState<boolean>(false);
    const [ feedback, setFeedback ] = useState<string>('');
    const [ loading, setLoading ] = useState<boolean>(false);

    // state counter to enable refreshing this component
    const [refreshCounter, setRefreshCounter] = useState(0);
    const refresh = () => setRefreshCounter(refreshCounter + 1);

    const appContext: IAppContext = useContext(AppContext);

    if (!appContext?.services?.firebaseService) {
        return null;
    }

    // as long as the user data has not yet been retrieved...
    if ((appContext as any)?.userData == null) {
      // ... refresh this component once a second
      window.setTimeout(refresh, 1000);
      return null;
    }

    let language = (appContext as any)?.userData?.language;

    const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const text = e.target.value;
        setFeedback(text);
    };

    const onSendFeedback = async () => {
        try {
            const feedbackTrimmed = feedback.trim();
            if (feedbackTrimmed.length < 3) {
                setError((language === "de")
                    ? "Schreiben Sie ruhig etwas. Ein paar Worte können uns sehr helfen."
                    : "Write something, anything. A few words go a long way");
                return;
            }
            setLoading(true);
            setError(undefined);
            await appContext.services.emailService.sendFeedbackEmail(feedbackTrimmed);
            setFeedback('');
            setFeedbackSent(true);
        } catch(error) {
            setError(error?.message || ((language === "de") ? "Fehler beim Absenden des Feedbacks" : 'Error sending feedback' ));
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={Style.formContainer}>
            <div className={Style.subcontainer}>
                {
                    feedbackSent ?
                        <ResultPageAbstract
                            status={"success"}
                            title={ (language === "de") ? "Feedback erfolgreich gesandt!" : "Successfully sent feedback!" }
                        />
                    :
                    <>
                        <h2>{ (language === "de") ? "Haben Sie Vorschläge für Verbesserungen bei SVS GIS?" : "Any suggestions for improvements to SVS GIS?" }</h2>
                        <CurrentEmailInput className={Style.currentEmail}/>
                        <TextArea value={feedback} className={Style.feedbackArea} onChange={onChange} rows={8} >
                            {feedback}
                        </TextArea>
                        <div className={Style.feedbackFooter}>
                            <br/>
                            <Button loading={loading} className={Style.sendFeedbackButton} onClick={onSendFeedback} {...BUTTON_PROPS}>
                                { (language === "de") ? "Absenden" : "Send" }
                            </Button>
                            <br/>
                            <br/>
                            <p>{ (language === "de") ? "Oder schreiben Sie uns direkt an: support@supervision.earth" : "Or email us directly at support@supervision.earth" }</p>
                        </div>
                        <div className={Style.errorContainer}>
                            { error &&
                                <Alert
                                    message={error}
                                    type="error"
                                    closable
                                    onClose={() =>setError(undefined)}
                                />
                            }
                        </div>
                    </>
                }
            </div>
        </div>
    );
}
const condition = (authUser: IFirebaseAuthUser) =>
  AuthenticationValidatorLogic.isAuthenticated(authUser);
export default withAuthorization(condition)(ROUTES.SIGN_IN.route)(FeedbackPage);