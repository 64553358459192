import React from "react";
import { IPipelineProperties } from "../../interfaces/map.interfaces";
import { IDeckGlInfoObject } from "./deck.gl.map";
import MapTooltip from "./map.tooltip";

function PipelineTooltip({
	clickedObject,
}: {
	clickedObject: IDeckGlInfoObject;
}) {
	if (
		!clickedObject ||
		(
			clickedObject.object?.properties as IPipelineProperties
		)?.objectType?.toLowerCase() !== "pipelinesegment"
	) {
		return null;
	}
	const properties = clickedObject.object.properties as IPipelineProperties;
	const pipid = properties.id;
	const name = properties.name;
	const companyName = localStorage.getItem("company");

	return (
		<MapTooltip position={{ x: 0, y: 0 }}>
			<span style={{ display: "inline-block", zIndex: 10 }}>
				<b>ID:</b> {pipid}
			</span>
			<br />
			<span>
				<b>Name:</b> {name}
			</span>
			<br />
			<span>
				<b>Owner:</b> {companyName}
			</span>
		</MapTooltip>
	);
}

export default PipelineTooltip;
