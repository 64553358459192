import React from "react";
import { showDate, translateEventState } from "../../utils/event.utils";

export default function EventTooltip(props) {
	const { properties, subTypeEnabled, language, coordinates, buttons, embeddedApp} = props;

	return (
		<span
			style={{
				width: "360px",
			}}>
			<p key={1}>
				<b>{language === "de" ? "Ereignis-ID" : "Event ID"}:</b> {properties.id}
			</p>
			<p key={2}>
				<b>{language === "de" ? "Orts-ID" : "Location ID"}:</b>{" "}
				{properties.locationId}
			</p>
			{
				"code" in properties ? <p key={15}>
				<b>{language === "de" ? "Code" : "Code"}:</b> {properties?.code}
			</p> : null}
			<p key={3}>
				<b>{language === "de" ? "Status" : "State"}:</b>{" "}
				{translateEventState(properties.state, language)}
			</p>
			<p key={4}>
				<b>{language === "de" ? "Art" : "Type"}:</b> {properties.type}
			</p>
			<p hidden={!subTypeEnabled} key={5}>
				<b>{language === "de" ? "Art" : "Sub Type"}:</b> {properties.subType}
			</p>
			<p key={6}>
				<b>{language === "de" ? "Letztes Update" : "Last Updated"}:</b>{" "}
				{showDate(properties.date)}
			</p>
			<p key={7}>
				<b>{language === "de" ? "Ort" : "Location"}:</b>{" "}
				{coordinates[0].toFixed(5)}, {coordinates[1].toFixed(5)}
			</p>
			{embeddedApp ? 
				<p key={8}>
					<b>Initial Description:</b>{" "}
					{properties.comment}
				</p> : 
				<p key={8}>
					<b>{language === "de" ? "Kommentar" : "Comment"}:</b>{" "}
					{properties.comment}
				</p>
			}
			
			<p key={9}>
				<b>{language === "de" ? "Author" : "Author"}:</b> {properties.author}
			</p>
			{embeddedApp && properties.note ? <p key={10}>
				<b>Note:</b>{" "} {properties.note}
			</p> : null}
			
			
			<p hidden={!properties.markerName} key={11}>
				<b>{language === "de" ? "Leitung" : "Pipeline"}:</b>{" "}
				{properties.markerName}
			</p>
			<p hidden={!properties.markerName} key={12}>
				<b>{language === "de" ? "Schilderpfahl" : "Marker Position"}:</b>{" "}
				{properties.markerPosition}
			</p>
			<p hidden={!properties.CH4} key={13}>
				<b>CH4:</b> {properties.CH4}
			</p>
			<p hidden={!properties.rate} key={14}>
				<b>Rate:</b> {properties.rate}
			</p>
			{buttons}
		</span>
	);
}
