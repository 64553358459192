import {
	IImageService,
	IFirebaseService,
} from "../interfaces/services.interfaces";
import AbstractService from "./abstract.service";
import { env } from "./../env";

export default class ImageService
	extends AbstractService
	implements IImageService
{
	constructor({ firebaseService }: { firebaseService: IFirebaseService }) {
		super({ firebaseService });
	}

	public async uploadImage(file: File, fileName: string): Promise<any> {
		const url = `${env.REACT_APP_SERVER_URL}/image/upload`;
		const form = new FormData();

		form.append("imageFile", file, fileName);
		console.log(form);
		try {
			await this.axiosInstance({
				method: "post",
				url: url,
				data: form,
				headers: { "Content-Type": "multipart/form-data" },
			});
			console.log(`${fileName} uploaded successfully`);
		} catch (error) {
			console.log(error);
			console.error(`Failed to upload ${fileName}`);
		}
	}
}
