import React, {useContext} from "react";
import AppContext from "../../context/app.context";
import {Form, Icon, Input} from "antd";
import {IAppContext} from "../../interfaces/context.interfaces";
import Style from './CurrentEmail.module.css';

interface IProps {
    className?: string;
}

export default function CurrentEmailInput(props: IProps) {
    const appContext: IAppContext = useContext(AppContext);
    const { className } = props;
    if (!appContext.services?.firebaseService) {
        return null;
    }
    return (
        <Form.Item style={{marginTop: '30px', marginBottom: '30px'}}>
            <Input
                className={className}
                prefix={<Icon className={Style.emailPrefix} type="mail" />}
                disabled
                value={appContext.services.firebaseService.auth.currentUser?.email || ''}
            />
        </Form.Item>
    );
};
