import axios, {AxiosInstance} from 'axios';
import {IFirebaseService} from "../interfaces/services.interfaces";

export default class AbstractService {
    protected readonly firebaseService: IFirebaseService;
    protected axiosInstance: AxiosInstance;

    protected constructor({firebaseService}: {firebaseService: IFirebaseService}) {
        this.firebaseService = firebaseService;

        const axiosDefaultConfig = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        this.axiosInstance = axios.create(axiosDefaultConfig);

        this.axiosInstance.interceptors.request.use(async (config) => {
            const idToken = await this.firebaseService.auth.currentUser?.getIdToken();
            config.headers.Authorization =  `Bearer ${idToken}`;
            return config;
        })
    }
}
