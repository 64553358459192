import { CloseCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import React from "react";

const ImageModal: React.FC<{
  open: boolean;
  imageUrl: string;
  setOpen: (s: boolean) => void;
  setImageUrl: (v: string) => void;
}> = ({ open, imageUrl, setOpen, setImageUrl }) => {
  return (
    <Modal
      visible={open}
      style={{
        background: "none !important",
        backgroundColor: "transparent !important",
        height: "600",
      }}
      centered
      width={800}
      footer={null}
      closeIcon={
        <CloseCircleOutlined
          style={{ fontSize: "24px" }}
          onClick={() => {
            setOpen(false);
            setImageUrl("");
          }}
        />
      }
    >
      <img
        src={imageUrl}
        width="100%"
        style={{
          boxShadow: "0 0 10px 1px",
          borderRadius: "20px",
          background: "none !important",
        }}
      />
    </Modal>
  );
};

export default ImageModal;
