/**
 * ensure that if eventState is none of the allowed states, it is active by default
 */
export function consolidateEventState(eventState: string): string {
	switch (eventState) {
		case "archived":
		case "only report change":
		case "do not report":
		case "observe":
		case "closed":
		case "removed":
		case "change":
		case "new":
			return eventState;
		default:
			return "active";
	}
}

export function translateEventState(
	eventState: string,
	language: string
): string {
	if (language === "de") {
		let EVENT_STATE_TO_DE = {
			archived: "archiviert",
			"only report change": "nur Änderung melden",
			active: "aktiv",
			"do not report": "nicht mehr melden",
			closed: "geschlossen",
			observe: "beobachten",
			removed: "entfernt",
			new: "neu",
		};
		return EVENT_STATE_TO_DE[eventState];
	}
	return eventState;
}

export function showDate(eventDate: string): string {
	if (eventDate) {
		eventDate = eventDate.split("/").join("-");
		if (eventDate.indexOf("T") > -1) {
			eventDate = eventDate.split("T")[0];
		}
	}
	return eventDate;
}

export function getGMapLink(coordinate: number[]) {
	return `https://www.google.com/maps/search/?api=1&query=${coordinate[1]},${coordinate[0]}`;
}

export function getNewEventId(eventId: string) {
	// generating new evenId
	const currentId: any = eventId.split("_") as Array<string>;
	let eventNumber = (parseInt(currentId.slice(-1)) + 1).toString();
	while (eventNumber.length < 3) {
		eventNumber = "0" + eventNumber;
	}
	let newEventId = currentId.slice(0, -1).join("_") + "_" + eventNumber;
	return newEventId;
}
