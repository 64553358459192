import React from 'react';
import AuthUserContext from '../../context/authentication.context';
import {withFirebase} from "./with.firebase";
import {IFirebaseService} from "../../interfaces/services.interfaces";
import {IFirebaseAuthUser} from "../../interfaces/authentication.interfaces";
import AuthenticationValidatorLogic from "../../logic/authenticationValidatorLogic";

export interface IProps {
    firebase: IFirebaseService;
}

export interface IState {
    authUser: IFirebaseAuthUser;
}

export const withAuthentication = <P extends IProps>(Component: React.ComponentType<P>) => {
    class WithAuthentication extends React.Component<P, IState> {
        authListener?: () => void;

        state = {
            authUser: null,
        };
        public componentDidMount() {
            this.authListener = this.authStateChangeListener();
        }
        public componentWillUnmount() {
            if (this.authListener) {
                this.authListener();
            }
        }
        public render() {
            const { authUser } = this.state;
            return (
                <AuthUserContext.Provider value={authUser}>
                    <Component {...this.props} />
                </AuthUserContext.Provider>
            );
        }

        private authStateChangeListener = () => {
            const { firebase } = this.props;
            return firebase.auth.onAuthStateChanged(
                (authUser: IFirebaseAuthUser) => {
                    AuthenticationValidatorLogic.isAuthenticated(authUser)
                        ? this.setState({ authUser })
                        : this.setState({ authUser: null });
                },
            );
        }
    }

    return withFirebase(WithAuthentication);
};
