import { CloseCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import React, { useEffect, useRef } from 'react'

const VideoModal: React.FC<{
  open: boolean
  videoUrl: string
  setOpen: (s: boolean) => void
  setVideoUrl: (v: string) => void
}> = ({ open, videoUrl, setOpen, setVideoUrl }) => {
  const videoRef = useRef<HTMLVideoElement>()
  return (
    <Modal
      visible={open}
      style={{
        background: 'none !important',
        backgroundColor: 'transparent !important',
      }}
      centered
      width={800}
      footer={null}
      closeIcon={
        <CloseCircleOutlined
          style={{ fontSize: '24px' }}
          onClick={() => {
            setOpen(false)
            setVideoUrl('')
          }}
        />
      }
    >
      <video
        ref={videoRef}
        src={videoUrl}
        width="768px"
        controls
        style={{
          boxShadow: '0 0 10px 1px',
          borderRadius: '20px',
          background: 'none !important',
        }}
      />
    </Modal>
  )
}

export default VideoModal
