import {
  IFirebaseService,
  IGridService,
} from "../interfaces/services.interfaces";
import AbstractService from "./abstract.service";
import { env } from "../env";

export default class GridService
  extends AbstractService
  implements IGridService
{
  constructor({ firebaseService }: { firebaseService: IFirebaseService }) {
    super({ firebaseService });
  }

  public async getMyData(projectId: string): Promise<any> {
    const url = `${env.REACT_APP_SERVER_URL}/grid/my_data`;
    return await this.axiosInstance.get(url, { params: { projectId } });
  }
}
