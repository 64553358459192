export const SIGN_IN = {
  route: "/",
  label: "Log In",
};
export const MAP = {
  route: "/map",
  label: "Map",
};
export const BETA_SIGN_UP = {
  route: "/beta",
  label: "Beta Sign-up",
};
export const HOME = {
  route: "/signin",
  label: "Sign In",
};
export const ACCOUNT = {
  route: "/account",
  label: "Account",
};
export const HELP = {
  route: "/help",
  label: "Help",
};
export const FEEDBACK = {
  route: "/feedback",
  label: "Feedback",
};
export const PIPELINES = {
  route: "/pipelines",
  label: "Pipelines",
};
export const SEGMENTS = {
  route: "/segments",
  label: "segments",
};
export const EVENTS = {
  route: "/events",
  label: "Events",
};
export const EVENTS_ARCHIVE = {
  route: "/events-archive",
  label: "Archive",
};
export const PASSWORD_FORGET = {
  route: "/forgot-password",
  Label: "Forgot Password?",
};
export const CHANGE_PASSWORD = {
  route: "/change-password",
  label_en: "Change password",
  label_de: "Passwort ändern",
};
export const CHANGE_EMAIL = {
  route: "/change-email",
  label_en: "Change email address",
  label_de: "Email-Adresse ändern",
};
export const DELETE_ACCOUNT = {
  route: "/delete-account",
  label_en: "Delete account",
  label_de: "Account löschen",
};
export const REAUTHENTICATE_PROMPT = {
  route: "/reauthenticate",
  Label: "Reauthenticate using credentials",
};
export const REPORTS = {
  route: "/reports",
  label: "Reports",
};
// export const CHANGELOG = {
//   route: "/changelog",
//   label: "Changelog",
// };

export const IMPRINT = {
  route: "https://supervision.earth/impressum/",
  label: "Imprint"
}

export const SHAREPOINT = {
  route: '/sharepoint',
  label: 'Sharepoint',
}
