export function capitalize(str: string) {
    return str[0].toUpperCase() + str.substr(1);
}

export function trimObjectStringValues(object: {[key: string]: string | any}): void {
    for (const key in object) {
        const value = object[key];
        if (typeof value === 'string') {
            object[key] = value.trim();
        }
    }
}
