import {
	IFirebaseService,
	ISviEventService
} from "../interfaces/services.interfaces";
import AbstractService from "./abstract.service";
import { IServerEvent } from "../interfaces/map.interfaces";
import { env } from './../env';

export default class SviEventService
	extends AbstractService
	implements ISviEventService {
	constructor({ firebaseService }: { firebaseService: IFirebaseService }) {
		super({ firebaseService });
	}

	public async getMyData(): Promise<any> {
		const url = `${env.REACT_APP_SERVER_URL}/svievent/my_data`;
		return await this.axiosInstance.get(url);
	}

	public async updateEvent(
		previousEvent: IServerEvent,
		newType: string = null,
		newSubType: string = null,
		newState: string = null,
		newNote: string = null,
		author: string = null,
		comment: string = null,
		date : string = null,
		code:string = null,
		area:string = null
	): Promise<void> {
		const url = `${env.REACT_APP_SERVER_URL}/svievent/add`;

		return await this.axiosInstance.post(url, {
			eventId: previousEvent.properties.id,
			locationId: previousEvent.properties.locationId,
			type: newType ? newType : previousEvent.properties.type,
			subType: newSubType ? newSubType : previousEvent.properties.subType,
			markerName: previousEvent.properties.markerName,
			markerPosition: previousEvent.properties.markerPosition,
			state: newState ? newState : previousEvent.properties.state,
			comment: comment,
			code :  code,
			area : area,
			date : date,
			note : newNote,
			author: author,
			projectId: previousEvent.properties.projectId,
			job: "Feedback",
			image: previousEvent.properties.image,
			confidence: 1.0,
			qa: "SVSGIS",
			geometryType: "Point",
			geometryCoordinates: previousEvent.geometry.coordinates,
			gridArea : previousEvent.properties.gridArea
		});
	}

	public async addHeliEvent(event: IServerEvent): Promise<any> {
		const url = `${env.REACT_APP_SERVER_URL}/svievent/addHeli`;	

		return await this.axiosInstance.post(url, {
			eventId: event.properties.id,
			locationId: event.properties.locationId,
			type: event.properties.type,
			detectedBy : event.properties.detectedBy,
			subType: event.properties.subType,
			markerName: event.properties.markerName,
			markerPosition: event.properties.markerPosition,
			state: event.properties.state,
			comment: event.properties.comment,
			author: event.properties.author,
			projectId: event.properties.projectId,
			job: "Feedback",
			image: event.properties.image,
			confidence: 1.0,
			qa: "SVSGIS",
			geometryType: "Point",
			geometryCoordinates: event.geometry.coordinates,
		});
	}
}
