import { Dropdown, Menu, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { IViewState } from "../../interfaces/map.interfaces";
import { copyToClipboard } from "./map.utils";

function ContextMenu({
	children,
	viewState,
	point,
}: {
	children: JSX.Element;
	viewState: IViewState;
	point: number[];
}) {
	const [visibleState, setVisibleState] = useState({ visible: false });
	const [textCoordinate, setTextCoordinate] = useState<string>();

	useEffect(() => {
		const coordinate = String(point).split(",");
		setTextCoordinate(
			coordinate[1].substring(0, 8) + ", " + coordinate[0].substring(0, 8)
		);
	}, [point]);

	useEffect(() => {
		setVisibleState({ visible: false });
	}, [viewState]);
	const handleVisibleChange = (flag) => {
		setVisibleState({ visible: flag });
	};

	const handleMenuClick = (e) => {
		if (e.key === "1") {
			setVisibleState({ visible: false });
		}
		copyToClipboard(textCoordinate);
	};

	const menu = (
		<Menu theme="dark">
			<Menu.Item key="1" onClick={handleMenuClick}>
				<Tooltip title="Click to Copy" key="1">
					{textCoordinate}
				</Tooltip>
			</Menu.Item>
		</Menu>
	);
	return (
		<Dropdown
			overlay={menu}
			trigger={["contextMenu"]}
			placement="topLeft"
			onVisibleChange={handleVisibleChange}
			visible={visibleState.visible}>
			{children}
		</Dropdown>
	);
}

export default ContextMenu;
