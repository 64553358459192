import React, {useContext, useState} from "react";
import {IFirebaseAuthUser} from "../../interfaces/authentication.interfaces";
import withAuthorization from "../HOC/with.authorization";
import * as ROUTES from "../../constants/routes";
import {Alert, Spin} from "antd";
import AppContext from "../../context/app.context";
import {ChangeFieldForm} from "../ChangeFieldForm/change.field.form";
import {ResultPageAbstract} from "../Result/resultPageAbstract";
import AuthenticationValidatorLogic from "../../logic/authenticationValidatorLogic";
import CurrentEmailInput from "../CurrentEmail/current.email.input";
import {IAppContext} from "../../interfaces/context.interfaces";
import Style from "../../styles/Pages.module.css";
import LabelStyle from '../../styles/Form.module.css';

const SucceededEmailChange = () => (
    <ResultPageAbstract
        status={"success"}
        title={ ((window as any).language === "de" ) ? "Email-Adresse erfolgreich geändert!" : "Successfully Changed Email!" }
        extra={[
            <Spin spinning={true} delay={1500}>
                <Alert
                    message={ ((window as any).language === "de" ) ? "Sie werden gleich ausgeloggt" : "You will be logged out shortly" }
                    description={ ((window as any).language === "de" ) ? "Bitte loggen Sie sich mit der neuen Email-Adresse ein" : "Please sign in with the new email" }
                    type="info"
                />
            </Spin>
        ]}
    />
);

const FIELD_NAME = "email";

const ChangeEmailPage = () => {

    // state counter to enable refreshing this component
    const [refreshCounter, setRefreshCounter] = useState(0);
    const refresh = () => setRefreshCounter(refreshCounter + 1);

    const appContext: IAppContext = useContext(AppContext);

    if (!appContext?.services?.firebaseService) {
        return null;
    }

    // as long as the user data has not yet been retrieved...
    if ((appContext as any)?.userData == null) {
      // ... refresh this component once a second
      window.setTimeout(refresh, 1000);
      return null;
    }

    let language = (appContext as any)?.userData?.language;
    (window as any).language = language;

    return (
        <div className={Style.page}>
            <div className={Style.formContainer}>
                <div className={Style.subcontainer}>
                    <ChangeFieldForm
                        formHeading={ (language === "de" ) ? ROUTES.CHANGE_EMAIL.label_de : ROUTES.CHANGE_EMAIL.label_en }
                        Extra={CurrentEmailInput}
                        SuccessResult={SucceededEmailChange}
                        fieldName={FIELD_NAME}
                        fieldValidators={[
                            {
                                type: 'email',
                                message: ((language === "de" ) ? 'Die Eingabe ist keine valide Email-Adresse!' : 'The input is not a valid Email address!' ),
                            },
                        ]}
                        fieldNodes={
                            (key: string) => [
                                <div className={LabelStyle.inputAndLabelPlaceholder}>
                                    <input type="email" name={key} id={key} placeholder={"Email"}/>
                                    <label htmlFor={key}>Email</label>
                                </div>,
                                <div className={LabelStyle.inputAndLabelPlaceholder}>
                                    <input type="email" name={key} id={key} placeholder={ (language === "de" ) ? "Email wiederholen" : "Repeat Email" }/>
                                    <label htmlFor={key}>{ (language === "de" ) ? "Wiederholen" : "Repeat" }</label>
                                </div>

                            ]
                        }
                        fieldMethod={appContext.services.firebaseService.updateEmail.bind(appContext.services.firebaseService)}
                    />
                </div>
            </div>
        </div>
    );
};

const condition = (authUser: IFirebaseAuthUser) => AuthenticationValidatorLogic.isAuthenticated(authUser);

export default withAuthorization(condition)(ROUTES.SIGN_IN.route)(ChangeEmailPage);
