import { Form, Button, Alert, Progress } from "antd";
import { FormComponentProps } from "antd/es/form";
import React from "react";
import { withRouter } from "react-router-dom";
import {
  IUserService,
  IFirebaseService,
} from "../../interfaces/services.interfaces";
import { withFirebase } from "../HOC/with.firebase";
import { RouteComponentProps } from "react-router";
import { ILoginFormValues } from "../../interfaces/form.interfaces";
import { SignUpLink } from "../SignUp";
import { MAP } from "../../constants/routes";
import { PasswordForgetLink } from "../ForgotPassword";
import Style from "../../styles/Form.module.css";
import {
  formItemLayout,
  tailFormItemLayout,
} from "../../constants/signup.consts";
import AppContext from "../../context/app.context";
import { env } from "../../env";

const INITIAL_STATE = {
  error: undefined,
  showResendEmailDescription: false,
  succeededSendingEmail: false,
  loading: false,
};

interface IState {
  error?: string;
  showResendEmailDescription: boolean;
  succeededSendingEmail: boolean;
  loading: boolean;
}

interface IProps extends FormComponentProps, RouteComponentProps<any> {
  firebase: IFirebaseService;
  userService: IUserService;
}

class LoginForm extends React.Component<IProps, IState> {
  static contextType = AppContext;

  state = {
    ...INITIAL_STATE,
  };

  public render() {
    const {
      form: { getFieldDecorator },
    } = this.props;
    const {
      error,
      showResendEmailDescription,
      succeededSendingEmail,
      loading,
    } = this.state;
    return (
      <>
        <h1 className={Style.formHeading}>Login</h1>
        <Form onSubmit={this.handleSubmit} {...formItemLayout}>
          <Form.Item>
            {getFieldDecorator("email", {
              rules: [
                {
                  required: true,
                  message: "Please input your email",
                },
                {
                  type: "email",
                  message: "The input is not valid E-mail, no whitespaces",
                },
              ],
            })(
              <div className={Style.inputAndLabelPlaceholder}>
                <input
                  type="text"
                  name="email"
                  id="email"
                  placeholder="mail@example.com"
                />
                <label htmlFor="email">Email</label>
              </div>
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("password", {
              rules: [
                { required: true, message: "Please input your Password" },
              ],
            })(
              <div className={Style.inputAndLabelPlaceholder}>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Your password"
                />
                <label htmlFor="password">Password</label>
              </div>
            )}
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            {PasswordForgetLink()}
            <br />
            <br />
            <Button
              loading={loading}
              className={Style.formActionButton}
              type="primary"
              htmlType="submit"
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
        {SignUpLink()}
        <div className={Style.errorContainer}>
          {error && (
            <Alert
              message={error}
              description={
                showResendEmailDescription &&
                (succeededSendingEmail ? (
                  <Progress
                    type="circle"
                    width={20}
                    percent={100}
                    status="success"
                  />
                ) : (
                  <Button
                    size="small"
                    loading={loading}
                    onClick={this.onResendVerificationEmail}
                  >
                    Resend Verification Email
                  </Button>
                ))
              }
              type="error"
              closable
              onClose={this.onCloseRegistrationError}
            />
          )}
        </div>
      </>
    );
  }

  private onCloseRegistrationError = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    this.setState({ ...INITIAL_STATE });
  };

  private onResendVerificationEmail = async () => {
    const { firebase } = this.props;
    this.setState({ loading: true });
    try {
      await firebase.sendEmailVerification();
      this.setState({ succeededSendingEmail: true });
    } catch (e) {
      this.setState({
        error: e.message || "Error sending verification email",
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  componentDidMount() {
    const { firebase, userService, history } = this.props;
    // Perform side effects here
    const { redirect_key } = Object.fromEntries(
      new URLSearchParams(history.location.search)
    );

    if (redirect_key && redirect_key === env.REACT_APP_SVX_REDIRECT_KEY) {
      this.setState({ loading: true });
      firebase
        .signInUsingEmailPassword("demo@supervision.earth", "demo2022")
        .then(() => userService.signin())
        .then(() => history.push(MAP.route))
        .catch((e) => {
          console.log(e);
          this.setState({ loading: false });
        });
    }
  }

  private handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    const { form, firebase, history, userService } = this.props;
    e.preventDefault();
    this.setState({ ...INITIAL_STATE });
    form.validateFields(async (err: any, values: ILoginFormValues) => {
      if (!err) {
        const { email, password } = values;
        try {
          this.setState({ loading: true });
          await firebase.signInUsingEmailPassword(email.trim(), password);
          await userService.signin();
          history.push(MAP.route);
        } catch (e) {
          console.log(e);
          const emailNotVerified: boolean =
            firebase.auth.currentUser?.email?.toLowerCase() ===
              email.toLowerCase() && !firebase.auth.currentUser.emailVerified;
          if (emailNotVerified) {
            this.setState({ showResendEmailDescription: true });
          }
          this.setState({
            error: e.message || "error logging in to the auth service",
          });
        } finally {
          this.setState({ loading: false });
        }
      }
    });
  };
}

export default withFirebase(
  withRouter(Form.create<IProps>({ name: "login_form" })(LoginForm))
);
