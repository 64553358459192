import React, {useContext, useState} from "react";
import { ChangeFieldForm } from "../ChangeFieldForm/change.field.form";
import {IFirebaseAuthUser} from "../../interfaces/authentication.interfaces";
import withAuthorization from "../HOC/with.authorization";
import * as ROUTES from "../../constants/routes";
import authenticationValidatorLogic from "../../logic/authenticationValidatorLogic";
import AppContext from "../../context/app.context";
import {AccountSettingsLink} from "../Account";
import {ResultPageAbstract} from "../Result/resultPageAbstract";
import AuthenticationValidatorLogic from "../../logic/authenticationValidatorLogic";
import {IAppContext} from "../../interfaces/context.interfaces";
import Style from "../../styles/Pages.module.css";
import LabelStyle from "../../styles/Form.module.css";
import {capitalize} from "../../utils/string.utils";

const ChangedPasswordSuccessfully = () => {
    return (
        <ResultPageAbstract
            status="success"
            title={`Successfully Changed Password!`}
            extra={[
                AccountSettingsLink()
            ]}
        />
    );
};

const FIELD_NAME = "password";

const ChangePasswordPage = () => {

    // state counter to enable refreshing this component
    const [refreshCounter, setRefreshCounter] = useState(0);
    const refresh = () => setRefreshCounter(refreshCounter + 1);

    const appContext: IAppContext = useContext(AppContext);

    if (!appContext?.services?.firebaseService) {
        return null;
    }

    // as long as the user data has not yet been retrieved...
    if ((appContext as any)?.userData == null) {
      // ... refresh this component once a second
      window.setTimeout(refresh, 1000);
      return null;
    }

    let language = (appContext as any)?.userData?.language;

    return (
        <div className={Style.page}>
            <div className={Style.formContainer}>
                <div className={Style.subcontainer}>
                    <ChangeFieldForm
                        formHeading={ (language === "de" ) ? ROUTES.CHANGE_PASSWORD.label_de : ROUTES.CHANGE_PASSWORD.label_en }
                        SuccessResult={ChangedPasswordSuccessfully}
                        fieldName={FIELD_NAME}
                        fieldValidators={[
                            {
                                validator: authenticationValidatorLogic.validateStrongPassword
                            },
                        ]}
                        fieldNodes={
                            (key: string) => [
                                <div className={LabelStyle.inputAndLabelPlaceholder}>
                                    <input type="password" name={key} id={key} placeholder={capitalize(FIELD_NAME)}/>
                                    <label htmlFor={key}>{capitalize(FIELD_NAME)}</label>
                                </div>,
                                <div className={LabelStyle.inputAndLabelPlaceholder}>
                                    <input type="password" name={key} id={key} placeholder={ (language === "de" ) ? "Passwort wiederholen" : "Repeat password" }/>
                                    <label htmlFor={key}>{ (language === "de" ) ? "Wiederholen" : "Repeat" }</label>
                                </div>
                            ]
                        }
                        fieldMethod={appContext.services.firebaseService.updatePassword.bind(appContext.services.firebaseService)}
                    />
                </div>
            </div>
        </div>
    );
};

const condition = (authUser: IFirebaseAuthUser) => AuthenticationValidatorLogic.isAuthenticated(authUser);

export default withAuthorization(condition)(ROUTES.SIGN_IN.route)(ChangePasswordPage);
