import app from 'firebase/app';
import 'firebase/auth';
import firebase from "firebase";
import { IFirebaseService } from "../interfaces/services.interfaces";
import { FIREBASE_UNIQUE_ERROR_CODES, LOGOUT_AFTER_CHANGE_EMAIL_MS } from "../constants/firebase.consts";
import * as ROUTES from '../constants/routes';
import { env } from './../env';

class Firebase implements IFirebaseService {

    public readonly auth: firebase.auth.Auth;

    constructor() {
        const runtimeEnvConfig = {
            apiKey: env.REACT_APP_API_KEY,
            authDomain: env.REACT_APP_AUTH_DOMAIN,
            databaseURL: env.REACT_APP_DATABASE_URL,
            projectId: env.REACT_APP_PROJECT_ID,
            storageBucket: env.REACT_APP_STORAGE_BUCKET,
            messagingSenderId: env.REACT_APP_MESSAGING_SENDER_ID,
            appId: env.REACT_APP_ID,
        };

        app.initializeApp(runtimeEnvConfig);
        this.auth = app.auth();
    }

    public async signUpUsingEmailPassword(email: string, password: string): Promise<firebase.auth.UserCredential> {
        const userCredential = await this.auth.createUserWithEmailAndPassword(email, password);
        await this.sendEmailVerification();
        return userCredential;
    }

    public async sendEmailVerification(): Promise<void> {
        const actionCodeSettings = this.buildActionCodeSettings();
        await this.auth.currentUser?.sendEmailVerification(actionCodeSettings);
    }

    public async signInUsingEmailPassword(email: string, password: string): Promise<firebase.auth.UserCredential> {
        const userCredential = await this.auth.signInWithEmailAndPassword(email, password);
        const wasEmailVerified: boolean = !!userCredential.user?.emailVerified;
        if (!wasEmailVerified) {
            throw new Error('You must verify your email before trying to log-in. Please check you inbox');
        }
        return userCredential;
    }

    public async signOut(): Promise<void> {
        await this.auth.signOut();
    }

    public async resetPassword(email: string): Promise<void> {
        try {
            const actionCodeSettings = this.buildActionCodeSettings();
            await this.auth.sendPasswordResetEmail(email, actionCodeSettings);
        } catch (error) {
            if (error?.code?.toLocaleLowerCase() === FIREBASE_UNIQUE_ERROR_CODES.USER_NOT_FOUND) {
                return;
            } else {
                throw new Error(error.message);
            }
        }
    }

    public async updatePassword(password: string): Promise<void> {
        await this.auth.currentUser?.updatePassword(password);
    }

    public async updateEmail(newEmail: string): Promise<void> {
        await this.auth.currentUser?.updateEmail(newEmail);
        setTimeout(() => {
            this.signOut();
        }, LOGOUT_AFTER_CHANGE_EMAIL_MS);
    }

    public async deleteUser(): Promise<void> {
        await this.auth.currentUser?.delete();
    }

    public async reauthenticateUsingCredential(userProvidedPassword: string): Promise<firebase.auth.UserCredential | undefined> {
        const user = this.auth.currentUser;
        if (!user) {
            return;
        }
        const credential: firebase.auth.AuthCredential = app.auth.EmailAuthProvider.credential(
            user.email || "",
            userProvidedPassword
        );
        return this.auth.currentUser?.reauthenticateWithCredential(credential);
    }

    public async getIdToken(): Promise<string> {
        const idToken = await this.auth.currentUser?.getIdToken(true);
        if (!idToken) {
            throw new Error('Cannot access current user, try to log-out');
        }
        return idToken;
    }

    private buildActionCodeSettings(): firebase.auth.ActionCodeSettings | null {
        const urlOrigin = window.location.origin;
        if (!urlOrigin) {
            return null;
        }
        const actionCodeSettings = {
            url: `${urlOrigin}${ROUTES.MAP.route}`
        };
        return actionCodeSettings;
    }
}

export default Firebase;
