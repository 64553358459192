import { MessageApi } from 'antd/lib/message'
import Axios from 'axios'
import {
  IFirebaseService,
  ISharepointService,
} from '../interfaces/services.interfaces'
import AbstractService from './abstract.service'
import { env } from "../env"

const URL = `${env.REACT_APP_SERVER_URL}/sharepoint/upload`

export default class SharepointService
  extends AbstractService
  implements ISharepointService {
  constructor({ firebaseService }: { firebaseService: IFirebaseService }) {
    super({ firebaseService })
  }

  public async uploadSharepoint(file: File, setUploading: (v: Boolean) => {} | Boolean, message: MessageApi) {
    setUploading(true)
    const idToken = await this.firebaseService.auth.currentUser?.getIdToken();
    if (!idToken) return
    const form = new FormData()
    form.append('sharepoint', file)
    try {
      await Axios.post(URL, form, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        onUploadProgress: (e) => {
          console.log(e.loaded * 100 / e.total)
        }
      })
      message.success(`${file.name} uploaded successfully`)
    } catch (error) {
      console.log(error.response.data.message)
      message.error(`Failed to upload ${file.name}`)
    }
  }
}
