import React from "react";
import withAuthorization from "../HOC/with.authorization";
import { IFirebaseAuthUser } from "../../interfaces/authentication.interfaces";
import AuthUserContext from "../../context/authentication.context";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import AuthenticationValidatorLogic from "../../logic/authenticationValidatorLogic";
import Style from "../../styles/Pages.module.css";
import AppContext from "../../context/app.context";
import {IAppContext} from "../../interfaces/context.interfaces";

const AccountPage = () => {

    // state counter to enable refreshing this component
    const [refreshCounter, setRefreshCounter] = useState(0);
    const refresh = () => setRefreshCounter(refreshCounter + 1);

    const appContext: IAppContext = useContext(AppContext);

    if (!appContext?.services?.firebaseService) {
        return null;
    }

    // as long as the user data has not yet been retrieved...
    if ((appContext as any)?.userData == null) {
      // ... refresh this component once a second
      window.setTimeout(refresh, 1000);
      return null;
    }

    let language = (appContext as any)?.userData?.language;
    let languageSelValues = "";
    languageSelValues += "<option value='en'";
    if (language !== "de") {
      languageSelValues += " selected>English";
    } else {
      languageSelValues += ">Englisch";
    }
    languageSelValues += "</option>";
    languageSelValues += "<option value='de'";
    if (language === "de") {
      languageSelValues += " selected>Deutsch";
    } else {
      languageSelValues += ">German";
    }
    languageSelValues += "</option>";

    return (
      <AuthUserContext.Consumer>
        {(authUser) =>
          authUser && (

              <div className={Style.formContainer}>

                <h2>{ (language === "de" ) ? "Account-Einstellungen" : "Account Settings" }</h2>

                <h3>{ (language === "de" ) ? "Sprache" : "Language" }</h3>
                <p>

                <select name='language' id='selectLanguage' onChange={() => ChangeLanguageSelection(appContext)}
                    dangerouslySetInnerHTML={{ __html: languageSelValues }}>
                </select>

                </p>

                <h3>{ (language === "de" ) ? "Email" : "Email" }</h3>
                <p>{ (language === "de" ) ? "Adresse" : "Address" }: {authUser.email}</p>
                <p><Link to={ROUTES.CHANGE_EMAIL.route}>{ (language === "de" ) ? ROUTES.CHANGE_EMAIL.label_de : ROUTES.CHANGE_EMAIL.label_en }</Link></p>

                <h3>{ (language === "de" ) ? "Passwort" : "Password" }</h3>
                <p><Link to={ROUTES.CHANGE_PASSWORD.route}>{ (language === "de" ) ? ROUTES.CHANGE_PASSWORD.label_de : ROUTES.CHANGE_PASSWORD.label_en }</Link></p>

                <h3>{ (language === "de" ) ? "Account" : "Account" }</h3>
                <p><Link to={ROUTES.DELETE_ACCOUNT.route}>{ (language === "de" ) ? ROUTES.DELETE_ACCOUNT.label_de : ROUTES.DELETE_ACCOUNT.label_en }</Link></p>

              </div>

          )
        }
      </AuthUserContext.Consumer>
    );
};

export const AccountSettingsLink = () => (
  <p>
    Back to <Link to={ROUTES.ACCOUNT.route}>Account settings</Link>
  </p>
);

const ChangeLanguageSelection = (appContext: IAppContext) => {
  let newLang = (document.getElementById("selectLanguage") as any).value;
  appContext.services.userService.addData({
    language: newLang,
  }).then(() => { window.location.reload(); });
};

const condition = (authUser: IFirebaseAuthUser) =>
  AuthenticationValidatorLogic.isAuthenticated(authUser);
export default withAuthorization(condition)(ROUTES.SIGN_IN.route)(AccountPage);
