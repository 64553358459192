import React from "react";
import { Link } from "react-router-dom";
import {
  consolidateEventState,
  translateEventState,
  showDate,
  getGMapLink,
  getNewEventId,
} from "../../utils/event.utils";
import {
  exportEventToJsonWithMessage,
  exportEventsToCSV,
} from "../../utils/export.utils";
import Style from "../../styles/Pages.module.css";
import withAuthorization from "../HOC/with.authorization";
import { IFirebaseAuthUser } from "../../interfaces/authentication.interfaces";
import AuthenticationValidatorLogic from "../../logic/authenticationValidatorLogic";
import * as ROUTES from "../../constants/routes";
import AppContext from "../../context/app.context";
import { BUTTON_PROPS } from "../../constants/general";
import {
  Button,
  Table,
  Menu,
  Dropdown,
  Icon,
  Modal,
  Form,
  Radio,
  Input,
  Select,
} from "antd";
import { IServices } from "../../interfaces/services.interfaces";
import {
  IEventProperties,
  ILocation,
  IServerEvent,
} from "../../interfaces/map.interfaces";
import moment from "moment";
import UploadImage from "../Map/UploadImage";
import { UploadFile } from "antd/lib/upload/interface";
import HistoryModal from "./HistoryModal";
import { SearchOutlined } from "@ant-design/icons";

interface IProps {}

interface IState {
  locations: ILocation[];
  events: IServerEvent[];
  filteredEvents: IServerEvent[];
  refreshCounter: number;
  ModalText: string;
  visible: boolean;
  confirmLoading: boolean;
  newEventComment: string;
  newEventData: any;
  newEventState: string;
  currentEventState: string;
  //for types
  currentTypeState: string;
  newTypeState: string;
  currentSubTypeState: string;
  newSubTypeState: string;
  subTypeEnabled: boolean;
  userEmail: string;
  types: string[];
  subTypes: string[];
  detections: string[];
  markersEnabled: boolean;
  imageFile: UploadFile;
  showEventHistory: boolean;
  historyEvent?: IServerEvent | null;
  projectId?:string
  EventServiceselected?
}

const onArchivePage = function (): boolean {
  return window.location.pathname.indexOf("/events-archive") === 4;
};

const { Option } = Select;
class EventsPage extends React.Component<IProps, IState> {
  static contextType = AppContext;

  private services: IServices;
  public subTypeEnabled: boolean;

  

  constructor(props: any, context: any) {
    super(props);

    const projectId = context.projects?.app || null;

    this.services = context.services;

    this.state = {
      locations: [],
      events: [],
      filteredEvents: [],
      refreshCounter: 0,
      ModalText: "Content of the modal",
      visible: false,
      confirmLoading: false,
      newEventComment: "",
      newEventData: [],
      newEventState: "",
      currentEventState: "",
      currentTypeState: "",
      newTypeState: "",
      currentSubTypeState: "",
      newSubTypeState: "",
      subTypeEnabled: false,
      userEmail: "",
      types: [],
      subTypes: [],
      detections: [],
      markersEnabled: false,
      imageFile: null,
      showEventHistory: false,
      historyEvent: null,
      EventServiceselected : projectId === "svi" ? this.services.sviEventService : this.services.eventService
    };

    

    this.refreshData();
  }

  

  refreshData() {
    this.services.userService.getMyData().then((data: any) => {
      this.setState({
        userEmail: data?.data.email,
      });
    });

  
    this.state.EventServiceselected.getMyData()
      .then((data: any) => {
        console.log("got location data:");
        console.log(data);
        const locations: ILocation[] = data?.data;

        // we are however only interested in the latest (so first) entry of each location:
        let eventArr: IServerEvent[] = [];
        for (let i = 0; i < locations.length; i++) {
          eventArr.push(locations[i].events[0]);
        }
        console.log("converted it to events data:");
        console.log(eventArr);

        this.setState({
          locations: locations,
          events: eventArr,
          filteredEvents: eventArr,
        });
      })
      .catch((error: any) => {
        console.log("server error upon getting event data!");
        console.log(error);
        (window as any).encounteredError = true;
      });

    this.services.projectService
      .getMyData()
      .then((data: any) => {
        console.log(data?.data[0].detection);
        this.setState({
          types: data?.data[0].types,
          subTypes: data?.data[0].subTypes,
          detections: data?.data[0].detection,
          markersEnabled: data?.data[0].markersEnabled,
          subTypeEnabled: data?.data[0].subTypeEnabled,
        });
      })
      .catch((error: any) => {
        console.log("error getting project data!");
      });
  }

  updateEventState(e) {
    this.setState({
      newEventState: e.target.value,
    });
  }
  updateTypeState(value) {
    this.setState({
      newTypeState: value,
    });
  }

  updateSubTypeState(value) {
    this.setState({
      newSubTypeState: value,
    });
  }

  updateEdit(
    previousEvent: IServerEvent,
    newType: string,
    newSubType: string,
    newState: string,
    newComment: string,
    author: string
  ) {
    if (!newComment) {
      if (newType != previousEvent.properties.subType) {
        newComment = `Type: ${newType}\t`;
      }
      if (newState != previousEvent.properties.state) {
        newComment = newComment + `State: ${newState}`;
      }
    }
    

    previousEvent.properties.id = getNewEventId(previousEvent.properties.id);
    previousEvent.properties.image = this.state.imageFile
      ? previousEvent.properties.id + ".png"
      : "";

    this.state.EventServiceselected.updateEvent(
        previousEvent,
        newType,
        newSubType,
        newState,
        newComment,
        author
      )
      .then(() => {
        this.services.imageService.uploadImage(
          this.state.imageFile?.originFileObj as File,
          previousEvent.properties.image
        );
        this.refreshData();
      });
  }

  updateNav() {
    window.setTimeout(function () {
      if ((window as any)._appIndex) {
        (window as any)._appIndex.forceUpdate();
      }
    }, 10);
    window.setTimeout(function () {
      if ((window as any)._appIndex) {
        (window as any)._appIndex.forceUpdate();
      }
    }, 1000);
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({
      ModalText: "The modal will be closed after two seconds",
      confirmLoading: true,
    });
    this.updateEdit(
      this.state.newEventData,
      this.state.newTypeState,
      this.state.newSubTypeState,
      this.state.newEventState,
      this.state.newEventComment,
      this.state.userEmail
    );
    setTimeout(() => {
      this.setState({
        visible: false,
        confirmLoading: false,
      });
    }, 2000);
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      newEventComment: "",
      newEventState: "",
      currentEventState: "",
      newTypeState: "",
      currentTypeState: "",
      newSubTypeState: "",
      currentSubTypeState: "",
    });
  };

  updateEventComment(e) {
    this.setState({ newEventComment: e.target.value });
  }

  setFile = async (file: UploadFile) => {
    this.setState({ imageFile: file });
  };

  setCommentData(newEvent: any) {
    Object.assign(this.state.newEventData, newEvent);
  }

  options(options: string[]) {
    let optionsArr = [];

    for (let i = 0; i < options.length; i++) {
      optionsArr.push(
        <Option key={options[i] + "_" + i} value={options[i]}>
          {options[i]}
        </Option>
      );
    }

    return optionsArr;
  }

  onTableChange = (pagination, filters, sorter) => {
    let events = this.state.events;
    for (let filter of Object.keys(filters)) {
      let pattern = new RegExp(filters[filter][0], "i");
      events = events.filter((event) =>
        pattern.test(event?.properties[filter])
      );
    }
    this.setState({
      filteredEvents: events,
    });
  };

  filterText = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
    return (
      <span style={{ display: "flex" }}>
        <Input
          autoFocus
          placeholder="Type text here"
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => {
            confirm();
          }}
          onBlur={() => {
            confirm();
          }}
        ></Input>
        <Button
          onClick={() => {
            confirm();
          }}
          type="primary"
        >
          Search
        </Button>
        <Button
          onClick={() => {
            clearFilters();
          }}
          type="danger"
        >
          Reset
        </Button>
      </span>
    );
  };

  render() {
    // state counter to enable refreshing this component
    const refresh = () =>
      this.setState({ refreshCounter: this.state.refreshCounter + 1 });

    // as long as the user data has not yet been retrieved...
    if ((this.context as any)?.userData == null) {
      // ... refresh this component once a second
      window.setTimeout(refresh, 1000);
    }

    let language = (this.context as any)?.userData?.language;
    let tableRows = [];
    let events = this.state.filteredEvents;

    const columns: any = [
      {
        title: language === "de" ? "Ereignis-ID" : "Event ID",
        dataIndex: "id",
        key: "id",
        sorter: (a, b) => a.id.localeCompare(b.id),
        sortDirections: ["descend", "ascend"],
        filterDropdown: this.filterText,
        filterIcon: () => <Icon type="search" theme="outlined" />,
      },
      {
        title: language === "de" ? "Code" : "Code",
        dataIndex: "code",
        key: "code",
        sorter: (a, b) => a.code.localeCompare(b.code),
        defaultSortOrder: "ascend",
        sortDirections: ["ascend"],
        filterDropdown: this.filterText,
        filterIcon: () => <SearchOutlined />,
        onHeaderCell: () => ({
          onClick: (e) => e.preventDefault(), // Prevent clicking the header to sort
        }),
      },{
        title: language === "de" ? "Area" : "Area",
        dataIndex: "area",
        key: "area",
        sorter: (a, b) => a.area.localeCompare(b.area),
        sortDirections: ["descend", "ascend"],
        filterDropdown: this.filterText,
        filterIcon: () => <Icon type="search" theme="outlined" />,
      },

      {
        title: language === "de" ? "Letztes Update" : "Last Update",
        dataIndex: "date",
        key: "date",
        sorter: (a: IEventProperties, b: IEventProperties) =>
          moment(a.date).unix() - moment(b.date).unix(),
        sortDirections: ["descend", "ascend"],
        filterDropdown: this.filterText,
        filterIcon: () => <Icon type="search" theme="outlined" />,
      },
      {
        title: language === "de" ? "Status" : "State",
        dataIndex: "state",
        key: "state",
        sorter: onArchivePage()
          ? null
          : (a: IEventProperties, b: IEventProperties) =>
              a.state?.localeCompare(b.state),
        filters: onArchivePage()
          ? []
          : [
              {
                text: language === "de" ? "aktiv" : "active",
                value: language === "de" ? "aktiv" : "active",
              },
              {
                text:
                  language === "de"
                    ? "nur Änderung melden"
                    : "only report change",
                value:
                  language === "de"
                    ? "nur Änderung melden"
                    : "only report change",
              },
              {
                text: language === "de" ? "nicht mehr melden" : "do not report",
                value:
                  language === "de" ? "nicht mehr melden" : "do not report",
              },
            ],
        onFilter: (value, record) => record.state?.includes(value),
        sortDirections: onArchivePage() ? null : ["descend", "ascend"],
        filterDropdown: this.filterText,
        filterIcon: () => <Icon type="search" theme="outlined" />,
      },
      {
        title: language === "de" ? "Kommentar" : "Comment",
        dataIndex: "comment",
        filterDropdown: this.filterText,
        filterIcon: () => <Icon type="search" theme="outlined" />,
      },
      {
        title: "CH4",
        dataIndex: "CH4",
        hidden: !this.state.detections.includes("methane"),
      },
      {
        title: "Pipeline Number",
        dataIndex: "markerName",
        hidden: !this.state.markersEnabled,
        sorter: (a, b) =>
          (a.markerName || "").localeCompare(b.markerName || ""),
        sortDirections: ["descend", "ascend"],
        filterDropdown: this.filterText,
        filterIcon: () => <Icon type="search" theme="outlined" />,
      },
      {
        title: language === "de" ? "Author" : "Author",
        dataIndex: "author",
        filterDropdown: this.filterText,
        filterIcon: () => <Icon type="search" theme="outlined" />,
      },
      {
        title: language === "de" ? "Aktionen" : "Actions",
        dataIndex: "actions",
        align: "center",
      },
      {
        title: "SubType",
        filterDropdown: this.filterText,
        filterIcon: () => <Icon type="search" theme="outlined" />,
        hidden: true,
      },
    ].filter((a) => !a.hidden);

    let filteredColumns = []
    
    if (this.context.projects?.app === "svi") {
      filteredColumns = columns.filter(
      (column) => column.dataIndex !== "id"
    )} else {
      filteredColumns = columns.filter(
      (column) => column.dataIndex !== "code" && column.dataIndex !== "area")
    }


    for (let i = 0; i < events.length; i++) {
      let id = events[i]?.properties?.id;

      let eventType = events[i]?.properties?.type;

      let subType = events[i]?.properties.subType;

      let eventDate = showDate(events[i]?.properties?.date);

      let eventcode = events[i]?.properties?.code
      let eventArea = events[i]?.properties?.area

      let eventState = events[i]?.properties?.state;
      eventState = consolidateEventState(eventState);

      if (onArchivePage()) {
        // on events archive page, only show archived events
        if (eventState !== "archived") {
          continue;
        }
      } else {
        // on events page, do not show events of certain types
        if (
          eventState === "archived" ||
          eventState === "removed" ||
          eventState === "observe"
        ) {
          continue;
        }
      }

      // the event state printed in human-friendly language
      let eventStateLang = translateEventState(eventState, language);

      let eventComment = events[i]?.properties?.comment;

      let linkTarget =
        ROUTES.MAP.route + "?location=" + events[i]?.properties?.locationId;

      //Show Events History
      const showEventsHistory = (event: IServerEvent) => {
        this.setState({
          showEventHistory: true,
          historyEvent: event,
        });
        console.log(event, this.state);
      };

      const actionsMenu = (
        <Menu style={{ textAlign: "center" }}>
          <Menu.Item key="0">
            <Link to={linkTarget} onClick={this.updateNav}>
              {language === "de" ? "Anzeigen" : "View"}
            </Link>
          </Menu.Item>
          <Menu.Item
            key="1"
            onClick={() => {
              exportEventToJsonWithMessage({
                properties: events[i].properties,
                ...events[i].geometry,
              });
            }}
            hidden={eventState === "deleted"}
          >
            {language === "de" ? "Exportieren" : "Export"}{" "}
            <Icon type="export" />
          </Menu.Item>
          <Menu.Item
            key="2"
            onClick={() => {
              this.showModal();
              this.setCommentData(events[i]);
              this.setState({
                newEventState: events[i].properties.state,
                currentEventState: events[i].properties.state,
                newTypeState: events[i].properties.type,
                currentTypeState: events[i].properties.type,
                newSubTypeState: events[i].properties.subType,
                currentSubTypeState: events[i].properties.subType,
              });
            }}
          >
            {language === "de" ? "Bearbeiten" : "Edit"} <Icon type="edit" />
          </Menu.Item>
          <Menu.Item
            key="3"
            onClick={() => {
              window.open(getGMapLink(events[i].geometry.coordinates));
            }}
          >
            {language === "de"
              ? "Navigieren auf Google Maps "
              : "Navigate on Google Maps "}
            <Icon type="pushpin" />
          </Menu.Item>
          <Menu.Item
            key={"4"}
            onClick={showEventsHistory.bind(null, events[i])}
          >
            {language === "de" ? "Verlauf anzeigen" : "View History"}
          </Menu.Item>
        </Menu>
      );

      tableRows.push({
        key: i + 1,
        id: id,
        code:eventcode,
        area:eventArea,
        type: eventType,
        date: eventDate,
        state: eventStateLang,
        subType: subType,
        comment: eventComment,
        CH4: events[i].properties.CH4,
        markerName: events[i].properties.markerName,
        author: events[i].properties.author,
        actions: [
          <Button
            className={Style.mapTooltipButton}
            {...BUTTON_PROPS}
            size="small"
            key="1"
          >
            <Dropdown
              overlay={actionsMenu}
              trigger={["click", "hover"]}
              placement="bottomCenter"
            >
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <Icon type="caret-down" />
              </a>
            </Dropdown>
          </Button>,
        ],
      });
    }

    const exportAsCSV = (ifOnArchivePage) => {
      let eventsToExport = [];
      if (ifOnArchivePage) {
        for (let i = 0; i < this.state.events.length; i++) {
          if (this.state.events[i]?.properties?.state === "archived") {
            eventsToExport.push(this.state.events[i]?.properties);
          }
        }
      } else {
        for (let i = 0; i < this.state.events.length; i++) {
          if (this.state.events[i]?.properties?.state !== "archived") {
            eventsToExport.push(this.state.events[i]?.properties);
          }
        }
      }
      exportEventsToCSV(eventsToExport);
    };

    let title = "Events";
    if (language === "de") {
      title = "Ereignisse";
    }
    if (onArchivePage()) {
      if (language === "de") {
        title = "Archiv der Ereignisse";
      } else {
        title = "Events Archive";
      }
    }

    const menu = (
        <Menu>
            <Menu.Item key="1">
                <a href="#option1">Option 1</a>
            </Menu.Item>
            <Menu.Item key="2">
                <a href="#option2">Option 2</a>
            </Menu.Item>
            <Menu.Item key="3">
                <a href="#option3">Option 3</a>
            </Menu.Item>
        </Menu>
    );

    const stateCommentForm = () => (
      <Form>
        <Form.Item label={language === "de" ? "Status" : "State"}>
          <Radio.Group
            defaultValue={this.state.newEventState}
            onChange={(e) => this.updateEventState(e)}
            value={this.state.newEventState}
          >
            <Radio.Button value="active">
              {language === "de" ? "Aktiv" : "Active"}
            </Radio.Button>
            <Radio.Button value="only report change">
              {language === "de" ? "Nur Änderung Melden" : "Only Report Change"}
            </Radio.Button>
            <Radio.Button value="archived">
              {language === "de" ? "Archiv" : "Archive"}
            </Radio.Button>
            <Radio.Button value="do not report">
              {language === "de" ? "Nicht Mehr Melden" : "Do Not Report"}
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item label={language === "de" ? "Typ" : "Type"}>
          <Select
            style={{ width: 200 }}
            defaultValue={this.state.newTypeState}
            onChange={(value) => this.updateTypeState(value)}
            value={this.state.newTypeState}
          >
            {this.options(this.state.types)}
          </Select>
        </Form.Item>
        <span hidden={!this.state.subTypeEnabled} style={{ color: "black" }}>
          <Form.Item label={language === "de" ? "Sub Typ" : "SubType"}>
            <Select
              style={{ width: 200 }}
              defaultValue={this.state.newSubTypeState}
              onChange={(value) => this.updateSubTypeState(value)}
              value={this.state.newSubTypeState}
            >
              {this.options(this.state.subTypes)}
            </Select>
          </Form.Item>
        </span>
        <Form.Item label={language === "de" ? "Kommentar" : "Comment"}>
          <Input
            placeholder={
              language === "de"
                ? "Schreiben Sie Ihren Kommentar..."
                : "Write your comment..."
            }
            value={this.state.newEventComment}
            onChange={(e) => this.updateEventComment(e)}
          />
        </Form.Item>
      </Form>
    );

    return (
      <div className={Style.formContainer}>
        <h2>{title}</h2>

        <Table
          columns={filteredColumns}
          dataSource={tableRows}
          onChange={(pagination, filters, sorter) =>
            this.onTableChange(pagination, filters, sorter)
          }
        />
        <Button
          onClick={() => {
            exportAsCSV(onArchivePage());
          }}
        >
          {onArchivePage() ? "Export archived as CSV" : "Export as CSV"}
        </Button>
        <Modal
          title={language === "de" ? "Bearbeiten" : "Edit"}
          className={"events-page-comment" + Style.mapTooltipButton}
          centered
          okText={"Submit"}
          visible={this.state.visible}
          onOk={this.handleOk}
          confirmLoading={this.state.confirmLoading}
          onCancel={this.handleCancel}
          okButtonProps={{
            ...BUTTON_PROPS,
            size: "small",
            disabled:
              !(this.state.currentTypeState == this.state.newTypeState) ||
              !(this.state.currentEventState == this.state.newEventState) ||
              this.state.newEventComment
                ? false
                : true,
          }}
          cancelButtonProps={{
            type: "danger",
            shape: "round",
            size: "small",
            ghost: true,
          }}
          style={{ color: "black" }}
        >
          {stateCommentForm()}
          <UploadImage
            file={this.state.imageFile?.originFileObj as File}
            setFile={this.setFile}
          />
        </Modal>

        {this.state.showEventHistory && (
          <HistoryModal
            eventObject={{
              properties: this.state.historyEvent.properties,
              ...this.state.historyEvent.geometry,
            }}
            eventsData={this.state.locations}
            onClose={() => {
              this.setState({
                showEventHistory: false,
                historyEvent: null,
              });
            }}
            visible={this.state.showEventHistory}
          />
        )}
      </div>
    );
  }
}

const condition = (authUser: IFirebaseAuthUser) =>
  AuthenticationValidatorLogic.isAuthenticated(authUser);

export default withAuthorization(condition)(ROUTES.SIGN_IN.route)(EventsPage);
