import { InboxOutlined } from "@ant-design/icons";
import { Button, message, Row, Upload } from "antd";
import type { UploadFile, UploadProps } from "antd/es/upload/interface";
import React, { useContext, useState } from "react";
import AppContext from "../../context/app.context";

const Sharepoint = () => {
	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const [uploading, setUploading] = useState(false);

	const {
		services: { sharepointService },
	} = useContext(AppContext);

	const props: UploadProps = {
		multiple: true,
		onChange: (info) => {
			setFileList(info.fileList);
		},
		onRemove: (file) => {
			const index = fileList.indexOf(file);
			const newFileList = fileList.slice();
			newFileList.splice(index, 1);
			setFileList(newFileList);
		},
		beforeUpload: (file) => {
			setFileList([...fileList, file]);
			return false;
		},
		fileList,
	};

	const handleUpload = async (e: React.MouseEvent<HTMLDivElement>) => {
		if (fileList.length > 0) {
			console.log(fileList);
			setUploading(true);
			for (const file of fileList) {
				if (file.size > 10 ** 9) {
					message.error(`${file.name} is too large.\nMax File size is 1 GB`);
					continue;
				}
				await sharepointService.uploadSharepoint(
					file.originFileObj as File,
					setUploading,
					message
				);
			}
			setFileList([]);
			setUploading(false);
		}
	};

	const handleCancel = (e: React.MouseEvent<HTMLDivElement>) => {
		setFileList([]);
		setUploading(false);
	};

	const { Dragger } = Upload;

	return (
		<Dragger {...props} disabled={uploading} name="sharepoint">
			<p className="ant-upload-drag-icon">
				<InboxOutlined style={{ color: uploading ? "gray" : undefined }} />
			</p>
			<p className="ant-upload-text" color="primary">
				Click or drag file to this area to upload
			</p>
			<p className="ant-upload-hint">
				Support for a single or bulk upload. Max size per file is 1 GB.
			</p>

			<Row
				style={{
					marginTop: 2,
					gap: 5,
					display: "flex",
					justifyContent: "space-around",
					alignItems: "center",
				}}
				onClick={(e) => e.stopPropagation()}>
				<Button
					type="danger"
					disabled={fileList.length < 1}
					onClick={handleCancel}>
					Cancel
				</Button>
				<Button
					type="primary"
					disabled={fileList.length < 1}
					onClick={handleUpload}
					loading={uploading}>
					Upload
				</Button>
			</Row>
		</Dragger>
	);
};

export default Sharepoint;
