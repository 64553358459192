import AppContext from '../../context/app.context';
import React from "react";
import {IWithFirebase} from "../../interfaces/firebase.interfaces";
import {IAppContext} from "../../interfaces/context.interfaces";

export const withFirebase = <P extends IWithFirebase>(Component: React.ComponentType<P>) =>
    (props: Omit<P, keyof IWithFirebase>) => (
    <AppContext.Consumer>
        {(ctx: IAppContext) => <Component {...props as P} firebase={ctx.services.firebaseService} />}
    </AppContext.Consumer>
);
