import React from "react";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";

export const SignUpLink = () => (
	<>
		<p>
			Don't have an account?{" "}
			<Link to={ROUTES.HOME.route}>{ROUTES.HOME.label}</Link>
		</p>
		<p>
			<a href={ROUTES.IMPRINT.route} target="_blank">
				Imprint
			</a>
		</p>
	</>
);
