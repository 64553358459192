import {
  FeatureCollection,
  Geometry,
  LineString,
  MultiLineString,
} from "geojson";

export interface IInteractionState {
  inTransition?: boolean;
  isDragging?: boolean;
  isPanning?: boolean;
  isRotating?: boolean;
  isZooming?: boolean;
}

export enum TRANSITION_EVENTS {
  BREAK = 1,
  SNAP_TO_END = 2,
  IGNORE = 3,
}

export interface IViewState {
  longitude: number;
  latitude: number;
  zoom: number;
  pitch: number;
  bearing: number;
  transitionDuration?: string | number;
  transitionInterpolator?: object;
  transitionInterruption?: TRANSITION_EVENTS;
  transitionEasing?: (t: number) => number;
}

export interface IMapContext {
  closeTooltip: () => void;
}

export interface IPipelineProperties {
  userId: string;
  companyName: string;
  objectType: "pipelineSegment";
  id?: string;
  name: string;
  owner?: string;
  segment: number;
  lastUpdate: string;
  comments?: string;
  registeredCompanyName: string;
}

export interface IPipelineData
  extends FeatureCollection<
    LineString | MultiLineString,
    IPipelineProperties
  > {}

export interface ILocation {
  id: string;
  events: IServerEvent[];
}

export interface IServerEvent {
  geometry: IGeometry;
  properties: IEventProperties;
}

export interface IGeometry {
  type: "Point";
  coordinates: [number, number];
}

export interface IEvent {
  type: "Point";
  opacity?: number;
  coordinates: [number, number];
  properties: IEventProperties;
}

export interface IEventProperties {
  code?: string;
  id: string;
  area?:string
  locationId: string;
  type: string;
  sourcetype: string;
  subType: string;
  comment?: string;
  state?: string;
  date: string;
  markerName?: string;
  markerPosition?: string;
  image?: string;
  video?: string;
  author?: string;
  projectId?: string;
  job?: string;
  confidence?: string;
  rate?: number;
  CH4?: number;
  detectedBy?: string;
  gridArea?: string;
}

export interface IUser {
  email: string;
  language: string;
}

export interface IProject {
  area?: string;
  customerName?: string;
  customerNumber: number;
  detection?: string[];
  frequency?: string;
  language?: string;
  projectLeader?: string;
  projectName?: string;
  technicalLeader?: string;
  endDate?: Date;
  startDate?: Date;
  reporting?: string[];
  markers?: object[];
  markersEnabled: boolean;
  id: string;
  subTypeEnabled: boolean;
  droneImageEnabled?: boolean;
  subTypes?: string[];
  types?: string[];
  userId?: string;
  app?:string
}

export interface IDroneJob {
  jobId: string;
}

export interface IDronePath {
  properties: { [k: string]: string | number | Date };
  geometry: Geometry;
}

export interface IDroneImage {
  _id: string;
  properties: {
    projectId: string;
    gridarea: string;
    key: string;
  };
  geometry: IGeometry;
}
