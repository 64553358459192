import React, { Component } from 'react';
import {withFirebase} from "../HOC/with.firebase";
import {Alert, Button, Form, Result} from "antd";
import { FormComponentProps } from 'antd/es/form';
import {IFirebaseService} from "../../interfaces/services.interfaces";
import {IResetPasswordFormValues} from "../../interfaces/form.interfaces";
import Style from "../../styles/Form.module.css";
import {formItemLayout, tailFormItemLayout} from "../../constants/signup.consts";
import {LoginLink} from "../Login";

interface IProps extends FormComponentProps {
    firebase: IFirebaseService;
}

interface IState {
    error?: string;
    wasEmailSent: boolean;
    loading: boolean;
}

class PasswordForgetFormBase extends Component<IProps, IState> {
    state = {
        error: undefined,
        wasEmailSent: false,
        loading: false,
    };

    public render() {
        const {form: {getFieldDecorator}} = this.props;
        const {error, wasEmailSent, loading} = this.state;
        if (wasEmailSent) {
            return (
                <Result
                    status="success"
                    title="Successfully Sent a Password Reset Email!"
                    subTitle="An email with instructions will be waiting in your inbox."
                    extra={[
                        <Button type="primary" key="resend_password_reset_email" onClick={this.onResendEmail}>Resend Email</Button>
                    ]}
                />
            );
        }
        return (
            <>
            <h1>Reset Password</h1>
            <Form onSubmit={this.handleSubmit} {...formItemLayout}>
                <Form.Item>
                    {getFieldDecorator('email', {
                        rules: [
                            {
                                required: true, message: 'Please input your email!'
                            },
                            {
                                type: 'email',
                                message: 'The input is not valid E-mail!',
                            }
                        ],
                    })(
                        <div className={Style.inputAndLabelPlaceholder}>
                            <input
                                type="text"
                                name="email"
                                id="email"
                                placeholder="mail@example.com"
                            />
                            <label htmlFor="email">Email</label>
                        </div>
                    )}
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                    <Button loading={loading} className={Style.formActionButton} type="primary" htmlType="submit">
                        Reset My Password
                    </Button>
                </Form.Item>
            </Form>
            {LoginLink()}
            <div className={Style.errorContainer}>
                {error && <Alert
                    message={error}
                    type="error"
                    closable
                    onClose={this.onCloseRegistrationError}
                />}
            </div>
            </>
        );
    }

    private onCloseRegistrationError = () => {
        this.setState({error: undefined});
    };

    private onResendEmail = () => {
        this.setState({wasEmailSent: false});
    };

    private handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        const { firebase, form } = this.props;
        event.preventDefault();
        this.setState({error: undefined});
        form.validateFields(async (err: any, values: IResetPasswordFormValues) => {
            if (!err) {
                try {
                    this.setState({loading: true});
                    const { email } = values;
                    await firebase.resetPassword(email);
                    this.setState({wasEmailSent: true});
                } catch(error) {
                    this.setState({ error: error.message || "error sending email reset password" });
                } finally {
                    this.setState({loading: false});
                }
            }
        });
    };
}
const PasswordForgetForm = withFirebase(Form.create<IProps>({ name: 'forgot_password_form' })(PasswordForgetFormBase));
export { PasswordForgetForm };
