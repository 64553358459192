import React from 'react';
import { Spin } from 'antd';
import Style from './Animation.module.css';

export default function Spinner() {
    return (
        <div className={Style.spinner}>
            <Spin size={"large" as "large"}/>
        </div>
    );
}
