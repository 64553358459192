import {IFirebaseAuthUser} from "../interfaces/authentication.interfaces";
import zxcvbn from 'zxcvbn';
import * as _ from 'lodash';

export default class AuthenticationValidatorLogic {

    public static isAuthenticated(authUser: IFirebaseAuthUser): boolean {
        return !!authUser && authUser.emailVerified;
    }

    public static async validateStrongPassword(rule: any, value: any, callback: (...args: any[]) => any) {
        if (!_.isString(value)) {
            return;
        }
        const passwordStrengthResult = await zxcvbn(value);
        const passwordScore = passwordStrengthResult.score;
        if (passwordScore >= 3) {
            callback()
        } else {
            const feedback: string = passwordStrengthResult.feedback.warning + " " + passwordStrengthResult.feedback.suggestions;
            callback(
                feedback
            );
        }
    };
}
