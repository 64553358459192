import React from "react";
import withAuthorization from "../HOC/with.authorization";
import { IFirebaseAuthUser } from "../../interfaces/authentication.interfaces";
import AuthenticationValidatorLogic from "../../logic/authenticationValidatorLogic";
import * as ROUTES from "../../constants/routes";
import Sharepoint from "./Sharepoint";
import { Modal } from "antd";

const SharepointPage = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      {(window as any)._userData?.sharepoint ? (
        <Sharepoint />
      ) : (
        <div
          style={{
            color: "red",
            textAlign: "center",
            borderRadius: "10px",
            fontSize: "18px",
            padding: "20px",
            boxShadow: "1px 1px 5px 1px #ccc",
          }}
        >
          <p>You are not allowed to upload files.</p>
          <p>Contact the support for questions</p>
        </div>
      )}
    </div>
  );
};

const condition = (authUser: IFirebaseAuthUser) =>
  AuthenticationValidatorLogic.isAuthenticated(authUser);

export default withAuthorization(condition)(ROUTES.SIGN_IN.route)(
  SharepointPage
);
