import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "antd/dist/antd.css";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import Services from "./services";
import AppContext from "./context/app.context";
import { BrowserRouter } from "react-router-dom";
import { env } from "./env";

(async () => {
  const services = new Services();

  ReactDOM.render(
    <AppContext.Provider value={{ services, projects: null }}>
      <BrowserRouter basename={env.REACT_APP_GIS_PREFIX}>
        <App />
      </BrowserRouter>
    </AppContext.Provider>,
    document.getElementById("root")
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
})();
