import React from 'react';
import Lottie from 'react-lottie';
import * as SadHeartAnimation from '../../assets/lottie-broken-heart.json';

export default function SadHeart() {
    const defaultOptions = {
        autoplay: true,
        animationData: (SadHeartAnimation as any).default,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <div>
            <Lottie
                options={defaultOptions}
                height={400}
                width={400}
            />
        </div>
    );
}
