import React from 'react';
import { withRouter } from 'react-router-dom';
import {RouteComponentProps} from "react-router";
import {IFirebaseService} from "../../interfaces/services.interfaces";
import {IFirebaseAuthUser} from "../../interfaces/authentication.interfaces";
import {withFirebase} from "./with.firebase";
import AuthUserContext from '../../context/authentication.context';
import Spinner from '../Animations/Spinner';

interface IWithAuthorizationProps extends RouteComponentProps<any> {
    firebase: IFirebaseService;
}

interface IState {
    isLoading: boolean;
}

const withAuthorization = (condition: (authUser: IFirebaseAuthUser) => boolean) => (redirectRoute: string) =>
    <P extends IWithAuthorizationProps>(Component: React.ComponentType<Omit<P, keyof IWithAuthorizationProps>>) => {
    class WithAuthorization extends React.Component<P, IState> {
        authListener?: () => void;

        state = {
          isLoading: true,
        };

        componentDidMount() {
            const { firebase, history} = this.props;
            this.authListener = firebase.auth.onAuthStateChanged(
                authUser => {
                    this.setState({isLoading: false});
                    if (!condition(authUser)) {
                        history.push(redirectRoute);
                    }
                },
            );
        }
        componentWillUnmount() {
            if (this.authListener) {
                this.authListener();
            }
        }
        render() {
            const { isLoading } = this.state;
            if (isLoading) {
                return <Spinner/>;
            }
            return (<AuthUserContext.Consumer>
                {authUser =>
                    condition(authUser) ? <Component {...this.props} /> : null
                }
            </AuthUserContext.Consumer>);
        }
    }
    return withRouter(withFirebase(WithAuthorization));
};
export default withAuthorization;
