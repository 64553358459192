import { CloseCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import AppContext from '../../context/app.context'
import {
  IEvent,
  IEventProperties,
  ILocation,
} from '../../interfaces/map.interfaces'
import {
  consolidateEventState,
  showDate,
  translateEventState,
} from '../../utils/event.utils'

type Props = {
  eventsData: ILocation[]
  eventObject: IEvent
  onClose: () => void
  visible: boolean
}

const HistoryModal: React.FC<Props> = ({
  eventsData,
  eventObject,
  onClose,
  visible,
}) => {
  const properties = eventObject.properties as IEventProperties
  const eventId = properties.id

  const [language, setLanguage] = useState<string>()
  const [subTypeEnabled, setSubTypeEnabled] = useState<boolean>()
  console.log('History Modal')
  let location = null
  let prevEvents = []
  let appendEvents = false

  const appContext = useContext(AppContext)
  useEffect(() => {
    appContext.services.userService.getMyData().then((data) => {
      setLanguage(data.data.language)
    })
    appContext.services.projectService.getMyData().then((data) => {
      setSubTypeEnabled(data.data[0].subTypeEnabled)
    })
  })

  for (let i = 0; i < eventsData.length; i++) {
    if (properties.locationId === eventsData[i].id) {
      location = eventsData[i]
      let otherEvents = location.events
      for (let j = 0; j < otherEvents.length; j++) {
        if (appendEvents) {
          prevEvents.push(otherEvents[j])
        }
        if (otherEvents[j].properties.id === eventId) {
          appendEvents = true
        }
      }
      break
    }
  }

  let prevEventsHtml = []
  for (let i = 0; i < prevEvents.length; i++) {
    let ev = prevEvents[i]
    let eventDate = ev?.properties?.date
    let eventState = consolidateEventState(ev?.properties?.state)
    let eventStateLang = translateEventState(eventState, language)

    let el = (
      <div key={i + '_div'}>
        <span>
          <b>{language === 'de' ? 'Datum' : 'Date'}:</b> {showDate(eventDate)}
        </span>
        <br />
        <span>
          <b>{language === 'de' ? 'Status' : 'State'}:</b> {eventStateLang}
        </span>
        <br />
        <span>
          <b>{language === 'de' ? 'Art' : 'Type'}:</b> {ev?.properties?.type}
        </span>
        <br />
        <span>
          <b>{language === 'de' ? 'Kommentar' : 'Comment'}:</b>{' '}
          {ev?.properties?.comment}
        </span>
      </div>
    )
    prevEventsHtml.push(el)
    el = <br key={i + '_br'} />
    prevEventsHtml.push(el)
  }
  if (prevEvents.length < 1) {
    let el = (
      <div key={'0_div'}>
        <span>
          <i>
            {language === 'de'
              ? 'Es gibt keine früheren Updates.'
              : 'There are no earlier updates.'}
          </i>
        </span>
      </div>
    )
    prevEventsHtml.push(el)
    el = <br key={'0_br'} />
    prevEventsHtml.push(el)
  }
  console.log(prevEventsHtml)
  return (
    <Modal
      closable
      visible={visible}
      centered
      className="history-modal"
      title={'Previous Updates'}
      style={{ minWidth: '500px', maxWidth: '800px' }}
      bodyStyle={{
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        borderRadius: '4px',
        fontSize: '18px',
        background: 'white !important',
      }}
      children={prevEventsHtml}
      footer={null}
      closeIcon={
        <CloseCircleOutlined
          style={{ fontSize: '24px', color: 'black' }}
          onClick={onClose}
        />
      }
    />
  )
}

export default HistoryModal
