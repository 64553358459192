import React, { useContext } from "react";
import LoginForm from "./login.form";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import { IFirebaseAuthUser } from "../../interfaces/authentication.interfaces";
import withAuthorization from "../HOC/with.authorization";
import AuthenticationValidatorLogic from "../../logic/authenticationValidatorLogic";
import EventImage from "../../assets/landing_page.jpg";
import { PublicPageWithForm } from "../PublicPageWithForm/publicPageWithForm";
import AppContext from "../../context/app.context";

const SignInPage = () => {
	const appContext = useContext(AppContext);
	return (
		<PublicPageWithForm imageSource={EventImage}>
			<LoginForm userService={appContext.services.userService} />
		</PublicPageWithForm>
	);
};

export const LoginLink = () => (
	<>
		<p>
			Already got an account?{" "}
			<Link to={ROUTES.SIGN_IN.route}>{ROUTES.SIGN_IN.label}</Link>
		</p>
		<p>
			<a href={ROUTES.IMPRINT.route} target="_blank">
				Imprint
			</a>
		</p>
	</>
);

const condition = (authUser: IFirebaseAuthUser) =>
	!AuthenticationValidatorLogic.isAuthenticated(authUser);
export default withAuthorization(condition)(ROUTES.MAP.route)(SignInPage);
